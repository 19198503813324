import React from 'react';
import Slick from "react-slick";

import { FiChevronRight } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick, space } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        width: 40,
        height: 40,
        right: -space,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      {<FiChevronRight style={{ fontSize: 20 }} />}
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, space } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        width: 40,
        height: 40,
        left: -space,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      {<FiChevronLeft style={{ fontSize: 20 }} />}
    </div>
  );
}

const Slider = (props) => {

  const { children, dots, items, space, center, style, fade } = props;

  const settings = {
    className: 'center',
    dots: dots,
    infinite: true,
    speed: 2000,
    slidesToShow: items,
    slidesToScroll: 1,
    centerMode: center,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 5000,
    fade: fade,
    nextArrow: <SampleNextArrow space={space} />,
    prevArrow: <SamplePrevArrow space={space} />
  };

  return (
    <Slick {...settings} style={style}>
      {children}
    </Slick>
  )
}

export default Slider;