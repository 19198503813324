import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader, ClipLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import {
  BsPencil,
  BsArrowLeft,
  BsPerson,
  BsEnvelope,
} from 'react-icons/bs';
import {
  IoLocationOutline,
  IoTransgenderOutline,
  IoCalendarClearOutline,
  IoFlagOutline,
} from 'react-icons/io5';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { AiOutlineSearch, } from 'react-icons/ai';

import { FuncService } from '../../../services/funcService';
import { showToastr } from '../../../services/themeService';
import {
  updateProfile,
  changePassword,
  passwordChangeEnd
} from '../../../store/session/actions';
import {
  getIngredientList,
} from '../../../store/app/actions';
import fileDialog from 'file-dialog';
import {
  LineEdit,
  CheckBox,
  Button,
  Pagination,
  Dialog,
  SignEdit
} from '../../../components';

import PrepImage from '../../../assets/image/prep.png';
import CookImage from '../../../assets/image/cook.png';


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 350,
  }),
  option: (provided, state) => ({
    ...provided,
  }),
};

const genderOptions = [
  {
    value: 1,
    label: 'Male',
  },
  {
    value: 2,
    label: 'Female',
  },
];



const useStyles = makeStyles((theme) => ({
  groups: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: "#E2EEFB",
    borderRadius: 6,
  },
  item: {
    width: '90%',
    margin: 'auto',
    borderRadius: 6,
    '&:hover': {
      opacity: 0.8,
    }
  },
  active: {
    width: '90%',
    margin: 'auto',
    borderRadius: 6,
    '&:hover': {
      backgroundColor: "#3490DD",
      opacity: 0.8,
    },
    backgroundColor: "#3490DD",
  },
  text: {
    color: 'white',
  },
  group: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 50,
  },
  small: {
    width: 18,
    height: 15,
    marginRight: 40,
  },
}));


const Edit = () => {

  const dispatch = useDispatch();

  const classes = useStyles();

  // const profile = useSelector(state => state.session.profile);
  const countries = useSelector(state => state.app.countries);
  const cuisines = useSelector(state => state.app.cuisines);
  const languages = useSelector(state => state.app.languages);
  const vegetarianTypes = useSelector(state => state.app.vegetarianTypes);
  const ingredientInfo = useSelector(state => state.app.ingredientInfo);
  const profile = useSelector(state => state.session.profile);
  const loading = useSelector(state => state.session.profileLoading);
  const passwordChanging = useSelector(state => state.session.passwordChanging);
  const passwordChangeResult = useSelector(state => state.session.passwordChangeResult);
  const [page, setPage] = useState(1);
  const [editMode, setEditMode] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [image, setImage] = useState('');
  const [imageContent, setImageContent] = useState(null);
  const [imageName, setImageName] = useState('');
  const [country, setCountry] = useState(null);
  const [birthDay, setBirthDay] = useState(new Date());
  const [gender, setGender] = useState(0);
  const [language, setLanguage] = useState(0);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedVegetarianTypes, setSelectedVegetarianTypes] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [keywords, setKeywords] = useState({
    cuisine: '',
    vegetarianType: '',
    ingredient: '',
  });
  const [search, setSearch] = useState({
    cuisine: '',
    vegetarianType: '',
    ingredient: '',
  });

  const [cuisineOpen, setCuisineOpen] = useState(false);
  const [vegetarianOpen, setVegetarianOpen] = useState(false);
  const [ingredientOpen, setIngredientOpen] = useState(false);
  const [group, setGroup] = useState(1);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [viewOld, setViewOld] = useState(false);
  const [viewNew, setViewNew] = useState(false);

  const count = 10;
  const defaultLanguage = profile?.langId ?? 1;

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const handlePageChange = (val) => {
    setPage(val);

    dispatch(getIngredientList(val, count, 'code', 'asc', keywords.ingredient));
  }

  const handleChangeImage = () => {
    fileDialog({ accept: 'image/*' })
      .then(file => {
        let reader = new FileReader();
        reader.onload = () => {
            console.log(reader.result);
            setImageContent(reader.result);
            setImageName(file[0].name);
        }

        reader.readAsDataURL(file[0]);
      });
  }

  const handleCuisineCheck = (id) => {
    let tmpCuisines = selectedCuisines.slice();
    if (!tmpCuisines.includes(id)) {
      tmpCuisines.push(id);
    } else {
      const index = tmpCuisines.indexOf(id);
      tmpCuisines.splice(index, 1);
    }

    setSelectedCuisines(tmpCuisines);
  }

  const handleVegetarianCheck = (id) => {
    let tmpVegetarianTypes = selectedVegetarianTypes.slice();
    if (!tmpVegetarianTypes.includes(id)) {
      tmpVegetarianTypes.push(id);
    } else {
      const index = tmpVegetarianTypes.indexOf(id);
      tmpVegetarianTypes.splice(index, 1);
    }

    setSelectedVegetarianTypes(tmpVegetarianTypes);
  }

  const handleIngredientCheck = (e, code) => {
    let tmpIngredients = selectedIngredients.slice();
    if (!tmpIngredients.includes(code)) {
      if (tmpIngredients.length > 4) {
        e.preventDefault();

        return;
      }

      tmpIngredients.push(code);
    } else {
      const index = tmpIngredients.indexOf(code);
      tmpIngredients.splice(index, 1);
    }

    setSelectedIngredients(tmpIngredients);
  }

  const handleEdit = () => {
    setName(profile.name);
    setEmail(profile.email);
    setCountry(profile.countryId ? countryOptions.find(country => country.value === profile.countryId) : countryOptions[0]);
    setCity(profile.city);
    setGender(genderOptions.find(gender => gender.label === profile.gender) ?? genderOptions[0]);
    setBirthDay(new Date(profile.birthday));
    setLanguage(languageOptions.find(language => language.value === defaultLanguage) ?? languageOptions[0]);
    setImage(null);

    setEditMode(true);
  }

  const handleSave = () => {
    console.log('name', name);
    console.log('email', email);
    console.log('country', country);
    console.log('city', city);
    console.log('gender', gender);
    console.log('birthDay', birthDay);
    console.log('language', language);
    console.log('image', image);

    if (!name || !email || !country || !city || !gender || !birthDay || !language) {
      showToastr('error', 'Input request', 'Please complete form.');

      return;
    }

    let updatedProfile = {};

    updatedProfile.name = name;
    updatedProfile.email = email;
    updatedProfile.city = city;
    updatedProfile.country = country.value;
    updatedProfile.birthday = birthDay;
    updatedProfile.gender = gender.label;
    updatedProfile.langId = language.value;
    updatedProfile.cuisineList = profile.cuisines;
    updatedProfile.vegetarianTypeList = profile.vegetarianTypes;
    updatedProfile.unlikeList = profile.UnlikeIngredients;
    updatedProfile.image = imageContent;
    updatedProfile.imageName = imageName;
    if (profile.userGroupId)
      updatedProfile.userGroupId = profile.userGroupId;

    console.log(updateProfile.image);
    dispatch(updateProfile(updatedProfile));

    setEditMode(false);
  }

  const handleCancel = () => {
    setEditMode(false);
  }

  const handleChangePassword = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setViewNew(false);
    setViewOld(false);
    setGroup(5);
  }

  const handleConfirmChangePassword = () => {
    if (!passwordChanging)
      dispatch(changePassword(oldPassword, newPassword, confirmPassword));
  }

  const handleCancelChangePassword = () => {
    setGroup(1);
  }

  const handleEdits = (type) => {
    if (type == 0) {
      setSelectedCuisines(profile.cuisines);
      setCuisineOpen(true);
    } else if (type == 1) {
      setSelectedVegetarianTypes(profile.vegetarianTypes);
      setVegetarianOpen(true);
    } else if (type == 2) {
      setSelectedIngredients(profile.unlikeIngredients.map(ingredient => ingredient.id));
      setIngredientOpen(true);
    }
  }

  const handleApply = (type) => {
    let updatedProfile = {};
    updatedProfile.name = profile.name;
    updatedProfile.email = profile.email;
    updatedProfile.city = profile.city;
    updatedProfile.country = profile.countryId;
    updatedProfile.birthday = profile.birthday;
    updatedProfile.gender = profile.gender;
    updatedProfile.image = profile.image;
    if (profile.userGroupId)
      updatedProfile.userGroupId = profile.userGroupId;
    if (type == 0) {
      updatedProfile.cuisineList = selectedCuisines;
      updatedProfile.vegetarianTypeList = profile.vegetarianTypes;
      updatedProfile.unlikeList = profile.unlikeIngredients;
    }
    if (type == 1) {
      updatedProfile.cuisineList = profile.cuisines;
      updatedProfile.vegetarianTypeList = selectedVegetarianTypes;
      updatedProfile.unlikeList = profile.likeIngredients;
    }
    if (type == 2) {
      updatedProfile.cuisineList = profile.cuisines;
      updatedProfile.vegetarianTypeList = profile.vegetarianTypes;
      updatedProfile.unlikeList = selectedIngredients;
    }

    dispatch(updateProfile(updatedProfile));

    if (type == 0)
      setCuisineOpen(false);
    else if (type == 1)
      setVegetarianOpen(false);
    else if (type == 2)
      setIngredientOpen(false);
  }

  const handleSearch = (type, e) => {
    if (type === 'ingredient') {
      handlePageChange(1);
    } else {
      setSearch({ ...search, [type]: keywords[type] });
    }

    if (e)
      e.preventDefault();
  }

  const handleGroup = (group) => {
    setGroup(group);
  }

  if (group === 5 && passwordChangeResult === true && !passwordChanging) {
    console.log("Ending password change");
    dispatch(passwordChangeEnd());
    setGroup(1);
  }

  if (!profile || !cuisines || !vegetarianTypes || !languages) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color='#FC9C52' size={20} margin={5} />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="HV-center h-100">
        <ClipLoader color='#FC9C52' size={35} />
      </div>
    );
  }

  let cuisineArray = [];
  profile.cuisines.map(cuisine => {
    cuisineArray.push(cuisines[cuisine].name);
  });

  let vegetarianArray = [];
  profile.vegetarianTypes.map(vegetarian => {
    vegetarianArray.push(vegetarianTypes[vegetarian].name);
  });

  let ingredientArray = [];
  profile.unlikeIngredients.map(ingredient => {
    ingredientArray.push(ingredient.description[defaultLanguage]);
  });

  let countryOptions = [];
  if (countries) {
    for (const id in countries) {
      countryOptions.push({
        value: parseInt(id),
        label: countries[id].name,
      });
    }
  }

  let languageOptions = [];
  if (languages) {
    for (const id in languages) {
      languageOptions.push({
        value: parseInt(id),
        label: languages[id].name,
      });
    }
  }


  return (
    <React.Fragment>
      <div style={{ backgroundColor: '#F5F8FE', }}>
        <div style={{ maxWidth: 1100, margin: 'auto', paddingTop: 50, paddingBottom: 50, }}>
          <div className="d-flex justify-content-between align-items-center mb-5" style={{ width: '100%', }}>
            <div>
              <p style={{ fontSize: 38, fontWeight: 'bold', }}>
                My Profile
              </p>
              <div style={{ width: 60, borderBottom: '2px solid #FC9C52', }}></div>
            </div>
          </div>
          <div className="mb-4">
            <Link to="/home" className="mr-2" style={{ color: '#6B6C72', textDecoration: 'none', fontWeight: 600 }}>
              <BsArrowLeft style={{ fontSize: 18, marginRight: 5, }} />Home
            </Link>
            <Link to="/preferences/edit" style={{ color: '#6B6C72', textDecoration: 'none', fontWeight: 600 }}>My Profile</Link>
          </div>
          <Grid container direction="row" justify="center">
            <Grid item xs={3} className="mr-5">
              <div className={classes.groups}>
                <List component="nav" aria-label="secondary mailbox folders">
                  <ListItem button className={group === 1 || group === 5 ? classes.active : classes.item} onClick={() => handleGroup(1)}>
                    <ListItemText primary="Profile" className={classes.text} />
                  </ListItem>
                  <ListItem button className={group === 2 ? classes.active : classes.item} onClick={() => handleGroup(2)}>
                    <ListItemText primary="Cuisines" />
                  </ListItem>
                  <ListItem button className={group === 3 ? classes.active : classes.item} onClick={() => handleGroup(3)}>
                    <ListItemText primary="Vegetarian Types" />
                  </ListItem>
                  <ListItem button className={group === 4 ? classes.active : classes.item} onClick={() => handleGroup(4)}>
                    <ListItemText primary="Exclude recipes with these ingredients" />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.group}>
                {
                  group === 1 ? (
                    <div>
                      <div className="d-flex justify-content-between">
                        <h4 className="text-center m-0">
                          Profile Setting
                        </h4>
                        <Button
                          variant=""
                          width={40}
                          height={40}
                          style={{ padding: 0 }}
                          onClick={handleEdit}
                        >
                          <BsPencil style={{ color: '#B8BABD', fontSize: 18, }} />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        {
                          editMode ? <Button variant="" width={40} height={40} style={{ padding: 0 }} onClick={handleChangeImage}><BsPerson style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} /></Button> :
                          (updateProfile.image == null ? <BsPerson style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} /> : null)
                        }
                        <div>
                          <p className="profile-subject">Name</p>
                          {
                            editMode ?
                              <LineEdit
                                placeholder="Name"
                                style={{ height: 30, margin: 0, padding: 10, width: 350, }}
                                value={name}
                                onChange={val => setName(val)}
                              /> :
                              <span className="profile-value">{profile.name}</span>
                          }
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <BsEnvelope style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} />
                        <div>
                          <p className="profile-subject">Email</p>
                          {
                            editMode ?
                              <LineEdit
                                placeholder="Email"
                                style={{ height: 30, margin: 0, paddingLeft: 10, width: 350, }}
                                readOnly
                                value={email}
                                onChange={val => setEmail(val)}
                              /> :
                              <span className="profile-value">{profile.email}</span>
                          }
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <IoLocationOutline style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} />
                        <div>
                          <p className="profile-subject">Country</p>
                          {
                            editMode ?
                              <Select
                                options={countryOptions}
                                isSearchable={true}
                                styles={customStyles}
                                // value={country}
                                defaultValue={countryOptions.find(countryOption => countryOption.value === profile.countryId) ?? countryOptions[0]}
                                onChange={val => setCountry(val)}
                              /> :
                              <span className="profile-value">{countries[profile.countryId]?.name ?? ''}</span>
                          }
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <IoLocationOutline style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} />
                        <div>
                          <p className="profile-subject">City</p>
                          {
                            editMode ?
                              <LineEdit
                                placeholder="City"
                                style={{ height: 30, margin: 0, paddingLeft: 10, width: 350, }}
                                value={city ? city : ''}
                                onChange={val => setCity(val)}
                              /> :
                              <span className="profile-value">{profile.city}</span>
                          }
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <IoTransgenderOutline style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} />
                        <div>
                          <p className="profile-subject">Gender</p>
                          {
                            editMode ?
                              <Select
                                options={genderOptions}
                                isSearchable={false}
                                styles={customStyles}
                                defaultValue={genderOptions.find(genderOption => genderOption.label === profile.gender) ?? genderOptions[0]}
                                onChange={val => setGender(val)}
                              /> :
                              <span className="profile-value">{profile.gender}</span>
                          }
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <IoCalendarClearOutline style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} />
                        <div>
                          <p className="profile-subject">Birthday</p>
                          {
                            editMode ?
                              <DatePicker selected={birthDay} onChange={date => setBirthDay(date)} /> :
                              <span className="profile-value">{FuncService.formatDate(profile.birthday)}</span>
                          }
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <IoFlagOutline style={{ color: '#6B6C72', marginRight: 40, fontSize: 24, }} />
                        <div>
                          <p className="profile-subject">Language</p>
                          {
                            editMode ?
                              <Select
                                options={languageOptions}
                                isSearchable={true}
                                styles={customStyles}
                                defaultValue={languageOptions.find(languageOption => languageOption.value === defaultLanguage) ?? languageOptions[0]}
                                onChange={val => setLanguage(val)}
                              /> :
                              <span className="profile-value">{languages[defaultLanguage]?.name ?? ''}</span>
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="contained"
                          bgColor="secondary"
                          fontColor="white"
                          style={{ height: 40, width: 280, marginRight: 20, }}
                          onClick={handleChangePassword}
                        >
                          <span style={{ fontSize: 18, fontWeight: 600 }}>Change Password</span>
                        </Button>
                      </div>
                      {
                        editMode ?
                          <div className="d-flex justify-content-center">
                            <Button
                              variant="contained"
                              bgColor="secondary"
                              fontColor="white"
                              style={{ height: 40, width: 120, marginRight: 20, }}
                              onClick={handleCancel}
                            >
                              <span style={{ fontSize: 18, fontWeight: 600 }}>Cancel</span>
                            </Button>
                            <Button
                              variant="contained"
                              bgColor="primary"
                              fontColor="white"
                              style={{ height: 40, width: 120, }}
                              onClick={handleSave}
                            >
                              <span style={{ fontSize: 18, fontWeight: 600 }}>Save</span>
                            </Button>
                          </div>
                          : null
                      }
                    </div>
                  ) : group === 2 ? (
                    <div>
                      <div className="d-flex justify-content-between">
                        <h4 className="text-center m-0">
                          Cuisines
                        </h4>
                        <Button
                          variant=""
                          width={40}
                          height={40}
                          style={{ padding: 0 }}
                          onClick={() => handleEdits(0)}
                        >
                          <BsPencil style={{ color: '#B8BABD', fontSize: 18, }} />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center">
                        <Avatar src={PrepImage} className={classes.small} />
                        <div>
                          <p className="profile-subject">Cuisines</p>
                          <span className="profile-value">{cuisineArray.join(', ')}</span>
                        </div>
                      </div>
                    </div>
                  ) : group === 3 ? (
                    <div>
                      <div className="d-flex justify-content-between">
                        <h4 className="text-center m-0">
                          Vegetarian Types
                        </h4>
                        <Button
                          variant=""
                          width={40}
                          height={40}
                          style={{ padding: 0 }}
                          onClick={() => handleEdits(1)}
                        >
                          <BsPencil style={{ color: '#B8BABD', fontSize: 18, }} />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center">
                        <Avatar src={CookImage} className={classes.small} />
                        <div>
                          <p className="profile-subject">Vegetarian Types</p>
                          <span className="profile-value">{vegetarianArray.join(', ')}</span>
                        </div>
                      </div>
                    </div>
                  ) : group === 4 ? (
                    <div>
                      <div className="d-flex justify-content-between">
                        <h4 className="text-center m-0">
                          Ingredients
                        </h4>
                        <Button
                          variant=""
                          width={40}
                          height={40}
                          style={{ padding: 0 }}
                          onClick={() => handleEdits(2)}
                        >
                          <BsPencil style={{ color: '#B8BABD', fontSize: 18, }} />
                        </Button>
                      </div>
                      <div className="d-flex align-items-center">
                        <Avatar src={CookImage} className={classes.small} />
                        <div>
                          <p className="profile-subject">Ingredients</p>
                          <span className="profile-value">{ingredientArray.join(', ')}</span>
                        </div>
                      </div>
                    </div>
                  ) : group === 5 ? (
                    <div>
                      <div className="justify-content-between sign-auth-form" style={{padding: 0}}>
                        <h4 className="text-center m-0">
                          Change Password
                        </h4>
                        <SignEdit
                          pass
                          eye
                          type="text"
                          name="old-pass"
                          view={viewOld}
                          onViewClick={() => setViewOld(!viewOld)}
                          placeholder="Old password"
                          value={oldPassword}
                          onChange={val => setOldPassword(val)}
                        />
                        <SignEdit
                          pass
                          eye
                          type="text"
                          name="new-pass"
                          placeholder="New password"
                          view={viewNew}
                          onViewClick={() => setViewNew(!viewNew)}
                          value={newPassword}
                          onChange={val => setNewPassword(val)}
                        />
                        <SignEdit
                          pass
                          type="text"
                          name="confirm-pass"
                          placeholder="Confirm password"
                          value={confirmPassword}
                          onChange={val => setConfirmPassword(val)}
                        />
                        <br></br>
                        <div className="d-flex justify-content-center">
                          <Button
                            variant="contained"
                            bgColor="secondary"
                            fontColor="white"
                            style={{ height: 40, width: 120, marginRight: 20, }}
                            onClick={handleCancelChangePassword}
                          >
                            <span style={{ fontSize: 18, fontWeight: 600 }}>Cancel</span>
                          </Button>
                          <Button
                            variant="contained"
                            bgColor="primary"
                            fontColor="white"
                            style={{ height: 40, width: 120, }}
                            onClick={handleConfirmChangePassword}
                          >
                            <span style={{ fontSize: 18, fontWeight: 600 }}>Confirm</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>An error has occurred</p>
                  )
                }
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        fullWidth={false}
        open={cuisineOpen}
        onClose={() => setCuisineOpen(false)}
      >
        <div className="d-flex justify-content-end mt-2">
          <form className="d-flex align-items-center" style={{ backgroundColor: '#F5F8FE', borderRadius: 6, height: 40, paddingRight: 20, }} onSubmit={e => handleSearch('cuisine', e)}>
            <input
              placeholder="Search"
              style={{ border: 'none', outline: 'none', paddingLeft: 20, borderRadius: 6, fontSize: 16, width: '100%', height: '100%', color: '#07122E', backgroundColor: '#F5F8FE' }}
              value={keywords.cuisine}
              onChange={val => setKeywords({ ...keywords, cuisine: val.target.value })}
            />
            <AiOutlineSearch className="pointer" style={{ fontSize: 18, marginRight: 5 }} onClick={() => handleSearch('cuisine')} />
          </form>
        </div>
        <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, height: 200, width: 400, overflowY: 'scroll', padding: '0 5%', marginTop: 10, }}>
          {
            Object.entries(cuisines).map((cuisine, index) => (
              cuisine[1].name.includes(search.cuisine) ? (
                <CheckBox
                  style={{ padding: '5px 0' }}
                  id={`cuisine_${parseInt(cuisine[0])}`}
                  key={`cuisine_${index}`}
                  checked={selectedCuisines.includes(parseInt(cuisine[0])) ? true : false}
                  onClick={() => handleCuisineCheck(parseInt(parseInt(cuisine[0])))}
                >
                  {cuisine[1].name}
                </CheckBox>
              ) : null
            ))
          }
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, marginRight: 10, }}
            onClick={() => setCuisineOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, }}
            onClick={() => handleApply(0)}
          >
            Apply
          </Button>
        </div>
      </Dialog>

      <Dialog
        fullWidth={false}
        open={vegetarianOpen}
        onClose={() => setVegetarianOpen(false)}
      >
        <div className="d-flex justify-content-end mt-2">
          <form className="d-flex align-items-center" style={{ backgroundColor: '#F5F8FE', borderRadius: 6, height: 40, paddingRight: 20, }} onSubmit={e => handleSearch('vegetarianType', e)}>
            <input
              placeholder="Search"
              style={{ border: 'none', outline: 'none', paddingLeft: 20, borderRadius: 6, fontSize: 16, width: '100%', height: '100%', color: '#07122E', backgroundColor: '#F5F8FE' }}
              value={keywords.vegetarianType}
              onChange={val => setKeywords({ ...keywords, vegetarianType: val.target.value })}
            />
            <AiOutlineSearch className="pointer" style={{ fontSize: 18, marginRight: 5 }} onClick={() => handleSearch('vegetarianType')} />
          </form>
        </div>
        <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, width: 400, height: 200, overflowY: 'scroll', padding: '0 5%', marginTop: 30, }}>
          {
            Object.entries(vegetarianTypes).map((vegetarian, index) => (
              vegetarian[1].name.includes(search.vegetarianType) ? (
                <CheckBox
                  style={{ padding: '5px 0' }}
                  id={`vegetarian_${parseInt(vegetarian[0])}`}
                  key={`vegetarian_${index}`}
                  checked={selectedVegetarianTypes.includes(parseInt(vegetarian[0])) ? true : false}
                  onClick={() => handleVegetarianCheck(parseInt(parseInt(vegetarian[0])))}
                >
                  {vegetarian[1].name}
                </CheckBox>
              ) : null
            ))
          }
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, marginRight: 10, }}
            onClick={() => setVegetarianOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, }}
            onClick={() => handleApply(1)}
          >
            Apply
          </Button>
        </div>
      </Dialog>

      <Dialog
        fullWidth={false}
        open={ingredientOpen}
        onClose={() => setIngredientOpen(false)}
      >
        <div className="d-flex justify-content-end mt-2">
          <form className="d-flex align-items-center" style={{ backgroundColor: '#F5F8FE', borderRadius: 6, height: 40, paddingRight: 20, }} onSubmit={e => handleSearch('ingredient', e)}>
            <input
              placeholder="Search"
              style={{ border: 'none', outline: 'none', paddingLeft: 20, borderRadius: 6, fontSize: 16, width: '100%', height: '100%', color: '#07122E', backgroundColor: '#F5F8FE' }}
              value={keywords.ingredient}
              onChange={val => setKeywords({ ...keywords, ingredient: val.target.value })}
            />
            <AiOutlineSearch className="pointer" style={{ fontSize: 18, marginRight: 5 }} onClick={() => handleSearch('ingredient')} />
          </form>
        </div>
        <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, width: 400, height: 200, overflowY: 'scroll', padding: '0 5%', marginTop: 30, }}>
          {
            ingredientInfo ? ingredientInfo.ingredients.map((ingredient, index) => (
              <CheckBox
                style={{ padding: '5px 0' }}
                id={`ingredient_${ingredient.id}`}
                key={`ingredient_${ingredient.id}`}
                checked={selectedIngredients.includes(ingredient.id) ? true : false}
                onClick={(e) => handleIngredientCheck(e, ingredient.id)}
              >
                {ingredient.friendlyDescription[defaultLanguage]}
              </CheckBox>
            )) : (
              <div className="HV-center h-100">
                <BeatLoader color='#FC9C52' size={20} margin={5} />
              </div>
            )
          }
        </div>
        <Pagination
          value={page ?? 1}
          maxPage={ingredientInfo?.pages}
          onUpdate={(val) => handlePageChange(val)}
          onChange={setPage}
        />
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, marginRight: 10, }}
            onClick={() => setIngredientOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, }}
            onClick={() => handleApply(2)}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default Edit;
