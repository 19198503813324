import React, { useState } from "react";
import { Auth } from "aws-amplify";

import {
  Button,
  LineEdit,
} from "../../../components";
import { history } from '../../../store/history';


const ForgotPassword = () => {

  const [email, setEmail] = useState('');

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      const data = await Auth.forgotPassword(email);
      console.log(data);
      history.push('/reset-pass', { email });
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Forgot password</h1>
        <p className="lead text-center">Enter your User Name to send your user name.</p>
      </div>

      <div style={{ maxWidth: 400, margin: 'auto', textAlign: 'center', }}>
        <form className="m-sm-4" onSubmit={handleSend}>
          <LineEdit
            type="text"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={val => setEmail(val)}
          />

          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={80}
            height={36}
            style={{ margin: 'auto', marginTop: 30, }}
            onClick={handleSend}
          >
            Send
          </Button>
        </form>
      </div>
    </React.Fragment>
  );
}


export default ForgotPassword;
