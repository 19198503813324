import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Topbar = props => {
  const { className, ...rest } = props;

  return (
    <>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
