import React from 'react';
import { Button as MUIButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
  button: props => ({
    color: props.color,
    borderRadius: props.rect ? 0 : props.height / 2,
    fontWeight: 550,
    textTransform: 'none',
    width: props.width,
    height: props.height,
    ...props.style,
  })
})

const Button = (props) => {

  const { bgColor, fontColor, rect, width, height, className, children, onClick, style, variant } = props;

  const styleProps = {
    color: fontColor,
    rect,
    style,
    width,
    height,
  }

  const classes = useStyles(styleProps);

  return (
    <MUIButton
      variant={variant ? variant : null}
      color={bgColor}
      className={`${classes.button} ${className}`}
      onClick={onClick}
      disableElevation
    >
      {children}
    </MUIButton>
  );
}

export default Button;