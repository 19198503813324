import { createStore, applyMiddleware } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import reducer from './reducer'

const persistConfig = {
  key: 'my_perfect_meal_userend',
  storage,
  blacklist: ['app',]
}
const persistedReducer = persistReducer(persistConfig, reducer)

const middleware = [thunk];
const store = createStore(persistedReducer, applyMiddleware(...middleware));

export default store;
