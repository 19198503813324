import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Rating from 'react-rating';
import { FaStar } from 'react-icons/fa';
import { FiStar } from 'react-icons/fi';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

import { getRecipe } from '../../../store/app/actions';
// import BackButton from '../../components/BackButton';

import PrepImage from '../../../assets/image/prep.png';
import CookImage from '../../../assets/image/cook.png';

import FoodImage1 from '../../../assets/image/tmp/food-1.png';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;


const AboutUs = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  const language = 1;


  return (
    <React.Fragment>
    </React.Fragment>
  )
}


export default AboutUs;
