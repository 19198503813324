import { Auth } from "aws-amplify";
import * as types from './actionTypes'
import { config } from '../../config';
import { ApiService } from '../../services/apiService';
import { showToastr } from '../../services/themeService';
import { history } from '../history';
import store from '../../store';


export const loginUser = (email, password) => {
  return (dispatch) => {
    dispatch(loginLoading())
    Auth.signIn(email, password)
      .then(cognitoUser => {
        console.log(cognitoUser);
        let user = {};
        user.id = cognitoUser.signInUserSession.idToken.payload.id;
        user.token = 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken;
        user.profileCreated = cognitoUser.signInUserSession.idToken.payload.profileCreated;

        console.log('user: ', user);

        dispatch(loginSuccess(user));

        dispatch(profileLoading());

        ApiService.getProfile(user.id)
          .then(data => {
            console.log('profile: ', data);
            dispatch(profileSuccess(data));

            if (user.profileCreated === 'true') {
              dispatch(updateFilter({ ...store.getState().session.filterOptions, cuisines: data.cuisines.slice(), vegetarianTypes: data.vegetarianTypes.slice() }));
              history.push('/home');
            } else {
              history.push('/preferences/wizard');
            }
          })
          .catch((error) => {
            console.log('profile error: ', error);
            showToastr('error', 'Profile Error', error.message);
            dispatch(profileError(error.message));
          });


      })
      .catch(error => {
        console.log('error signing in', error);
        showToastr('error', 'Log in Error', error.message);
        dispatch(loginError(error));
      });
  }
}

export const federatedLoginUser = (cognitoUser) => {
  return (dispatch) => {
    dispatch(loginLoading());

    let user = {};
    user.id = cognitoUser.signInUserSession.idToken.payload.id;
    user.token = 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken;
    user.profileCreated = cognitoUser.signInUserSession.idToken.payload.profileCreated;

    console.log('user: ', user);

    dispatch(loginSuccess(user));

    dispatch(profileLoading());

    ApiService.getProfile(user.id)
    .then(data => {
      console.log('profile: ', data);
      dispatch(profileSuccess(data));

      if (user.profileCreated === 'true') {
        history.push('/home');
      } else {
        history.push('/preferences/wizard');
      }
    })
    .catch((error) => {
      console.log('profile error: ', error);
      showToastr('error', 'Profile Error', error.message);
      dispatch(profileError(error.message));
    });
  }
}

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(logoutLoading());

    Auth.signOut()
      .then(() => {
        console.log('logout success');
        dispatch(logoutSuccess());
        history.push('/sign-in');
      })
      .catch(error => {
        console.log('error signing out: ', error);
      });
  }
}

export const registerUser = (email, password) => {
  return (dispatch) => {
    dispatch(registerLoading());

    Auth.signUp({ username: email, password })
      .then(cognitoUser => {
        console.log('register user: ', cognitoUser);
        dispatch(registerSuccess());

        history.push('/confirm-user', { email });
      })
      .catch(error => {
        console.log('error signing up', error);
        showToastr('error', 'Log in Error', error.message);
        dispatch(registerError(error.message));
      });
  }
}

export const changePassword = (oldPassword, newPassword, confirmPassword) => {
  return (dispatch, getState) => {
    if (newPassword !== confirmPassword) {
      showToastr("error", "Change Password", "Passwords do not match");
      return;
    }
    dispatch(passwordChanging());
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.changePassword(user, oldPassword, newPassword)
          .then(result => {
            console.log(result);
            showToastr("info", "Change Password", "Successfully changed the password");
            dispatch(passwordChangeSuccess());
          })
          .catch(error => {
            console.log("error changing password", error);
            dispatch(passwordChangeError());
            showToastr("error", "Change Password", error.message);
          })
      })
      .catch(error => {
        console.log("error changing password", error);
        dispatch(passwordChangeError());
        showToastr("error", "Change Password", error.message);
      })
  }
}

export const getProfile = () => {
  return (dispatch, getState) => {
    const { session } = getState();

    
    if (session?.user?.id) {
      dispatch(profileLoading())
      ApiService.getProfile(session.user.id)
      .then(data => {
        console.log('profile: ', data);
        dispatch(profileSuccess(data))
      })
      .catch((error) => {
        console.log('profile error: ', error);
        dispatch(profileError(error.message));
      });
    }
  }
}

export const updateProfile = (profile) => {
  return (dispatch, getState) => {
    const { session } = getState();

    dispatch(profileLoading());

    ApiService.updateProfile(session.user.id, profile)
      .then(data => {
        console.log('update profile: ', data);
        dispatch(profileSuccess(data))
      })
      .catch((error) => {
        console.log('update profile error: ', error);
        dispatch(profileError(error.message))
      });
  }
}

export const updateFilter = (filter) => {
  return (dispatch) => {

    dispatch(filterSuccess(filter));
  }
}

// export const refreshToken = () => {
//   return (dispatch) => {
//     dispatch(loginLoading())

//     Auth.currentSession()
//       .then(cognitoUser => {
//         console.log(cognitoUser);
//         let user = {};
//         user.id = cognitoUser.signInUserSession.idToken.payload.id;
//         user.token = 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken;
//         user.profileCreated = cognitoUser.signInUserSession.idToken.payload.profileCreated;

//         console.log('user: ', user);

//         dispatch(loginSuccess(user));
//       })
//       .catch(error => {
//         console.log('error signing in', error);
//         showToastr('error', 'Log in Error', error.message);
//         dispatch(loginError(error));
//       });
//   }
// }

export const loginLoading = () => ({
  type: types.LOGIN_LOADING
})

export const loginSuccess = user => ({
  type: types.LOGIN_SUCCESS,
  user
})

export const loginError = error => ({
  type: types.LOGIN_ERROR,
  error
})

const logoutLoading = () => ({
  type: types.LOGOUT_LOADING
})

const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
})

const logoutError = error => ({
  type: types.LOGOUT_ERROR,
  error
})

const passwordChanging = () => ({
  type: types.PASSWORD_CHANGING
})

export const passwordChangeEnd = () => ({
  type: types.PASSWORD_CHANGE_END
})

const passwordChangeSuccess = () => ({
  type: types.PASSWORD_CHANGE_SUCCESS
})

const passwordChangeError = () => ({
  type: types.PASSWORD_CHANGE_ERROR
})

const registerLoading = () => ({
  type: types.REGISTER_LOADING
})

const registerSuccess = () => ({
  type: types.REGISTER_SUCCESS,
})

const registerError = error => ({
  type: types.REGISTER_ERROR,
  error
})

const profileLoading = () => ({
  type: types.PROFILE_LOADING
})

const profileSuccess = (profile) => ({
  type: types.PROFILE_SUCCESS,
  profile,
})

const profileError = error => ({
  type: types.PROFILE_ERROR,
  error
});

const filterSuccess = (filter) => ({
  type: types.FILTER_SUCCESS,
  filter,
});