import { combineReducers } from 'redux';
import { reducer as toastr } from "react-redux-toastr";

import session from './session';
import app from './app';

export default combineReducers({
  toastr,
  session,
  app,
})
