import React from 'react';
import clsx from 'clsx';


const CheckBox = (props) => {

  const { id, style, children, checked, onClick } = props;


  return (
    <div className="d-flex align-items-center" style={style}>
      <input className="checkbox" type="checkbox" id={id} defaultChecked={checked} name={id} style={{ marginRight: 10, padding: 5, minWidth: 18, }} onClick={onClick} />
      <label htmlFor={id} style={{ margin: 0 }}>
        {children}
      </label>
    </div>
  );
}

export default CheckBox;