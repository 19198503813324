import React, { useState } from 'react';

import {
  HiOutlineUser,
  HiOutlineMail,
  HiOutlineLockClosed,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi';


const SignEdit = (props) => {

  const { user, email, pass, view, eye, placeholder, style, type, name, onViewClick, value, onChange, readOnly } = props;

  const [off, setOff] = useState(false);

  const handleClick = () => {
    setOff(!off);

    onViewClick();
  }


  return (
    <div className="sign-edit-container" style={style}>
      {user ? <HiOutlineUser size={20} /> : null}
      {email ? <HiOutlineMail size={20} /> : null}
      {pass ? <HiOutlineLockClosed size={20} /> : null}
      <input
        className="custom-sign-input"
        type={pass && !view ? 'password' : type}
        name={name}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        onChange={(val) => onChange(val.target.value)}
      />
      {eye ? (off ? <HiOutlineEyeOff className="pointer" size={20} onClick={handleClick} /> : <HiOutlineEye className="pointer" size={20} onClick={handleClick} />) : null}
    </div>
  );
}

export default SignEdit;