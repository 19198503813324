import React, { useEffect, useRef, useState, } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { withStyles, makeStyles, } from '@material-ui/core/styles';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { logoutUser } from '../../../store/session/actions';
import { history } from '../../../store/history';

import logoImage from '../../../assets/image/logo.png';

import {
  Button,
  SearchBar,
  Slider,
  CarouselItem1,
  CarouselItem2,
  CarouselItem3,
  CarouselItem4,
  Dialog,
} from '../../../components';

import { useStoryblok } from "../../../services/storyblokService";

import BlogImage1 from '../../../assets/image/blog1.png';
import BlogImage2 from '../../../assets/image/blog2.png';
import BlogImage3 from '../../../assets/image/blog3.png';
import BlogImage4 from '../../../assets/image/blog4.png';
import BlogImage5 from '../../../assets/image/blog5.png';

import MainImage from '../../../assets/image/header.png';
import DotsImage1 from '../../../assets/image/background-dots1.png';
import DotsImage2 from '../../../assets/image/background-dots2.png';
import PlanImage from '../../../assets/image/plan.png';
import BuyImage from '../../../assets/image/buy.png';
import PrepareImage from '../../../assets/image/prepare.png';
import TakeMealImage from '../../../assets/image/take-meal.png';
import ProgressImage from '../../../assets/image/progress.png';
import SectionImage1 from '../../../assets/image/section1.png';
import SectionImage2 from '../../../assets/image/section2.png';
import SectionImage3 from '../../../assets/image/section3.png';
import SectionImage4 from '../../../assets/image/section4.png';
import SectionImage5 from '../../../assets/image/section5.png';
import SectionImage6 from '../../../assets/image/section6.png';

import AvatarImage1 from '../../../assets/image/avatar1.png';
import AvatarImage2 from '../../../assets/image/avatar2.png';
import AvatarImage3 from '../../../assets/image/avatar3.png';
import AvatarImage4 from '../../../assets/image/avatar4.png';
import AvatarImage5 from '../../../assets/image/avatar5.png';
import AvatarImage6 from '../../../assets/image/avatar6.png';
import AvatarImage7 from '../../../assets/image/avatar7.png';

import MissionImage from '../../../assets/image/mission.png';
import VisionImage from '../../../assets/image/vision.png';


const NAVITEM_HOME = 1;
const NAVITEM_HUNGRYFORINSIGHTS = 2;
const NAVITEM_OUREXPERTS = 3;
const NAVITEM_ABOUTUS = 4;
const NAVITEM_HOWITWORKS = 5;


const NavBarItem = (props) => {

  const { children, style, active, onClick, text } = props;

  const className = clsx({
    "nav-bar-active": active,
  });

  return (
    <li style={style} onClick={onClick}>
      <div className={className}>
        {text}
      </div>
      {children}
    </li>
  );
}


const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  subTitleFont: {
    fontSize: 20,
    fontWeight: 'bold',
  }
}));


const LandingPage = () => {
  const slug = window.location.pathname === "/"
  ? "home"
  : window.location.pathname.slice(1).replace("/", "-");


  // const dispatch = useDispatch();
  const location = useLocation();

  const classes = useStyles();

  const homeRef = useRef();
  const aboutRef = useRef();
  const workRef = useRef();
  const expertRef = useRef();
  const hungryRef = useRef();

  // const contentList = useSelector(state => state.app.contents);

  const [index, setIndex] = useState(1);
  const [missionOpen, setMissionOpen] = useState(false);
  const [visionOpen, setVisionOpen] = useState(false);

  const preview = true;
  const storyPlan = useStoryblok("how-it-work-list/plan", preview);
  const storyMundial = useStoryblok("how-it-work-list/mundial-food-data-base", preview);

  let i = 0;
  if (location.pathname.includes('/home')) {
    i = NAVITEM_HOME;
  } else if (location.pathname.includes('/about-us')) {
    i = NAVITEM_ABOUTUS;
  } else if (location.pathname.includes('/how-it-works')) {
    i = NAVITEM_HOWITWORKS;
  } else if (location.pathname.includes('/our-expert')) {
    i = NAVITEM_OUREXPERTS;
  } else if (location.pathname.includes('/hungry-for-insights')) {
    i = NAVITEM_HUNGRYFORINSIGHTS;
  }

  const handleActive = (index) => {
    setIndex(index);

    if (index === NAVITEM_HOME) {
      homeRef.current.scrollIntoView({ behavior: 'smooth', });
    } else if (index === NAVITEM_ABOUTUS) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth', });
    } else if (index === NAVITEM_HOWITWORKS) {
      workRef.current.scrollIntoView({ behavior: 'smooth', });
    } else if (index === NAVITEM_OUREXPERTS) {
      expertRef.current.scrollIntoView({ behavior: 'smooth', });
    } else {
      hungryRef.current.scrollIntoView({ behavior: 'smooth', });
    }
  }

  useEffect(() => {
   handleActive(i); 
  })

  const handleMission = () => {
    setMissionOpen(true);
  }

  const handleVision = () => {
    setVisionOpen(true);
  }

  return (
    <div>
      <Dialog
        fullWidth={false}
        open={missionOpen}
        onClose={() => setMissionOpen(false)}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={3} className="mr-5">
            <Avatar src={MissionImage} className={classes.large} />
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom className={classes.subTitleFont}>
              Our Mission
            </Typography>
            <Typography gutterBottom>
              Our mission is to provide the intelligent tools for everyone be able to eat healthy and accordingly to their nutritional requirements.
            </Typography>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        fullWidth={false}
        open={visionOpen}
        onClose={() => setVisionOpen(false)}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={3} className="mr-5">
            <Avatar src={VisionImage} className={classes.large} />
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom className={classes.subTitleFont}>
              Our Vision
            </Typography>
            <Typography gutterBottom>
              Our vision is leading healthy eating habits to millions of people.
            </Typography>
            <Typography gutterBottom>
              Encourage a balance and vegetarian diet. Promote environmental sustainability.
            </Typography>
          </Grid>
        </Grid>
      </Dialog>

      <div ref={homeRef} className="d-flex flex-column align-items-center">
        <Slider items={1} space={-20} center={false} fade style={{ width: '100%' }}>
          <div>
            <div className="d-flex flex-column align-items-center" style={{ height: 730, backgroundImage: `url(${BlogImage1})`, backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', }}>
              <span className="text-center" style={{ width: 500, marginTop: 120, marginBottom: 40, fontSize: 56, fontWeight: 700 }}>
                Fast & Easy Vegetarian Recipes
              </span>
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                onClick={() => history.push('/vegetarian-foods')}
                width={150}
                height={50}
              >
                Try it now
              </Button>
            </div>
          </div>
          <div>
            <div className="d-flex flex-column align-items-center" style={{ height: 730, backgroundImage: `url(${BlogImage2})`, backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', }}>
              <span className="text-center" style={{ width: 500, marginTop: 120, marginBottom: 40, fontSize: 56, fontWeight: 700 }}>
                Rated Against Nutritional Value
              </span>
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                onClick={() => history.push('/vegetarian-foods')}
                width={150}
                height={50}
              >
                Try it now
              </Button>
            </div>
          </div>
          <div>
            <div className="d-flex flex-column align-items-center" style={{ height: 730, backgroundImage: `url(${BlogImage3})`, backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', }}>
              <span className="text-center" style={{ width: 500, marginTop: 120, marginBottom: 40, fontSize: 56, fontWeight: 700 }}>
                Adaptaded to your Own Needs
              </span>
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                onClick={() => history.push('/vegetarian-foods')}
                width={150}
                height={50}
              >
                Try it now
              </Button>
            </div>
          </div>
          <div>
            <div className="d-flex flex-column align-items-center" style={{ height: 730, backgroundImage: `url(${BlogImage4})`, backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', }}>
              <span className="text-center" style={{ width: 600, marginTop: 120, marginBottom: 40, fontSize: 56, fontWeight: 700 }}>
                Made by Nutritionists Just for You
              </span>
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                onClick={() => history.push('/vegetarian-foods')}
                width={150}
                height={50}
              >
                Try it now
              </Button>
            </div>
          </div>
          <div>
            <div className="d-flex flex-column align-items-center" style={{ height: 730, backgroundImage: `url(${BlogImage5})`, backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', }}>
              <span className="text-center" style={{ width: 710, marginTop: 120, marginBottom: 40, fontSize: 56, fontWeight: 700 }}>
                Food Preferences of Those Who Live Longer
              </span>
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                onClick={() => history.push('/vegetarian-foods')}
                width={150}
                height={50}
              >
                Try it now
              </Button>
            </div>
          </div>
        </Slider>



        <div className="d-flex justify-content-center" style={{ width: '100%', height: 100, }}>
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            rect
            width="50%"
            onClick={handleMission}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>Our Mission</span>
            <BsArrowRight style={{ fontSize: 30 }} />
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            rect
            width="50%"
            onClick={handleVision}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>Our Vision</span>
            <BsArrowRight style={{ fontSize: 30 }} />
          </Button>
        </div>

        <div ref={aboutRef} style={{ width: '100%', backgroundImage: `url(${DotsImage1})`, backgroundRepeat: 'no-repeat', backgroundPositionX: '100%', backgroundPositionY: 150 }}>
          <div className="d-flex justify-content-center align-items-start" style={{ margin: '120px auto', maxWidth: 1100, padding: '0 80px' }}>
            <img src={SectionImage1} style={{ width: '50%' }}></img>
            <div style={{ width: '50%', marginTop: 40, marginLeft: '10%' }}>
              <p style={{ fontSize: 20, fontWeight: 600, color: '#3490DD', display: 'inline-block' }}>About</p>
              <br></br>
              <p style={{ width: 60, paddingBottom: 15, whiteSpace: 'nowrap', fontSize: 38, fontWeight: 'bold', color: '#07122E', borderBottom: '2px solid #FC9C52', }}>
                My Perfect Meal
              </p>
              <p className="section-text-describe">
                We are a company that aims to promote healthy eating habits and environmental sustainability.
                Nutrition is the basis of a balanced lifestyle and a vegetarian diet has a varied benefits for our health and well-being but also, its earth friendly.
                Through a scientific and nutritional analysis of individuals daily needs, we can provide you the best recipes to achieve those nutritional doses.
                We worked together with different professionals, nutritionist and doctors, to ensure your health. With a vegetarian diet we guarantee that you will have all the nutrients that you need.
                Besides that, we are an international team, so we have a wide variety of recipes for you.
                It has never been easier to eat varied, healthy and vegetarian.
              </p>
            </div>
          </div>
        </div>

        <div ref={workRef} className="d-flex flex-column align-items-center" style={{ width: '100%', padding: '120px 0', backgroundImage: `url(${DotsImage2})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 0, backgroundPositionY: 250 }}>
          <div className="d-flex justify-content-center" style={{ width: 60, paddingBottom: 15, borderBottom: '2px solid #FC9C52', }}>
            <span style={{ whiteSpace: 'nowrap', fontSize: 38, fontWeight: 'bold', color: '#07122E', textAlign: 'center' }}>How It Works</span>
          </div>
          <div className="d-flex justify-content-center align-items-start" style={{ margin: '60px auto 0 auto', maxWidth: 1100, padding: '0 80px' }}>
            <div style={{ width: '50%', marginTop: 40, marginRight: '10%' }}>
              <div className="d-flex align-items-center">
                <img src={PlanImage} style={{ backgroundColor: '#74D39B', padding: 10, borderRadius: '50%', marginRight: 20 }}></img>
                <span style={{ maxWidth: 445, fontSize: 30, fontWeight: 700, color: '#07122E', }}>{storyPlan?.content?.Title}</span>
              </div>
              <p className="section-text-describe">
                {
                /* According to the information you provide, our nutritionists calculate your individual nutritional needs.
                That means your daily energetic needs but also macronutrients and micronutrients. And we know they are important for health and well-being.
                The goal is that the meals we provide to you, be the best to satisfy your daily needs but also promote your health. */
                storyPlan?.content?.Body
                }
              </p>
            </div>
            <img src={SectionImage2} style={{ width: '50%' }}></img>
          </div>

          <div className="d-flex justify-content-center align-items-start" style={{ margin: '80px auto 0 auto', maxWidth: 1100, padding: '0 80px' }}>
            <img src={SectionImage3} style={{ width: '50%' }}></img>
            <div style={{ width: '50%', marginTop: 40, marginLeft: '10%' }}>
              <div className="d-flex align-items-center">
                <img src={BuyImage} style={{ backgroundColor: '#F5DC60', padding: 10, borderRadius: '50%', marginRight: 20 }}></img>
                <span style={{ maxWidth: 445, fontSize: 30, fontWeight: 700, color: '#07122E', }}>{storyMundial?.content?.Title}</span>
              </div>
              <p className="section-text-describe">
                {
                /*
                We not only consider your nutrient needs, but also your food tastes.
                Our food database is global, and our recipes are prepared by professionals from all over the world.
                You can choose your meals according to your personal taste, as well as allowing yourself to travel through the world of gastronomy.
                */
               storyMundial?.content?.Body
                }
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center" style={{ width: '100%', backgroundImage: `url(${DotsImage2})`, backgroundRepeat: 'no-repeat', backgroundPositionX: '100%', backgroundPositionY: 350 }}>
          <div className="d-flex justify-content-center align-items-start" style={{ margin: '80px auto 0 auto', maxWidth: 1100, padding: '0 80px' }}>
            <div style={{ width: '50%', marginTop: 40, marginRight: '10%' }}>
              <div className="d-flex align-items-center">
                <img src={PrepareImage} style={{ backgroundColor: '#FF7C7C', padding: 10, borderRadius: '50%', marginRight: 20 }}></img>
                <span style={{ maxWidth: 445, fontSize: 30, fontWeight: 700, color: '#07122E', }}>Be Healthy</span>
              </div>
              <p className="section-text-describe">
                You can enjoy a variety of meals respecting your food tastes and meeting your nutritional needs.
                Eating healthy in an easy, practical and tasty way.
              </p>
            </div>
            <img src={SectionImage4} style={{ width: '50%' }}></img>
          </div>

          <div className="d-flex justify-content-center align-items-start" style={{ margin: '80px auto 0 auto', maxWidth: 1100, padding: '0 80px' }}>
            <img src={SectionImage5} style={{ width: '50%' }}></img>
            <div style={{ width: '50%', marginTop: 40, marginLeft: '10%' }}>
              <div className="d-flex align-items-center">
                <img src={TakeMealImage} style={{ backgroundColor: '#C5AAFF', padding: 10, borderRadius: '50%', marginRight: 20 }}></img>
                <span style={{ maxWidth: 445, fontSize: 30, fontWeight: 700, color: '#07122E', }}>Take Meal</span>
              </div>
              <p className="section-text-describe">
                According to the meals we provide you can access your weekly menus.
                You can also sync your google calendar with My Perfect Meal App and the menus you choose appear on your calendar.
                We make your meals prep so much easier.
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center" style={{ width: '100%', backgroundImage: `url(${DotsImage2})`, backgroundRepeat: 'no-repeat', backgroundPositionX: 0, backgroundPositionY: 400 }}>
          <div className="d-flex justify-content-center align-items-start" style={{ margin: '80px auto 0 auto', maxWidth: 1100, padding: '0 80px' }}>
            <div style={{ width: '50%', marginTop: 40, marginRight: '10%' }}>
              <div className="d-flex align-items-center">
                <img src={ProgressImage} style={{ backgroundColor: '#5BBAFF', padding: 10, borderRadius: '50%', marginRight: 20 }}></img>
                <span style={{ maxWidth: 445, fontSize: 30, fontWeight: 700, color: '#07122E', }}>Grocery list shopping</span>
              </div>
              <p className="section-text-describe">
                When choosing your menus, we will prepare a list of groceries to help you organize your purchases and make your time more efficient.
                Besides that, having this grocery list helps you to avoid buying food you don't need. So, you save money and avoid food waste at the same time!
              </p>
            </div>
            <img src={SectionImage6} style={{ width: '50%' }}></img>
          </div>

          {/* <div className="d-flex flex-column align-items-center" style={{ marginTop: 220, width: '100%' }}>
          <div className="d-flex justify-content-center" style={{ width: 60, paddingBottom: 15, borderBottom: '2px solid #FC9C52', }}>
            <span style={{ whiteSpace: 'nowrap', fontSize: 38, fontWeight: 'bold', color: '#07122E', textAlign: 'center' }}>Our Services Taken</span>
          </div>
          <p style={{ maxWidth: 445, marginTop: 20, fontSize: 16, fontWeight: 400, color: '#6B6C72', }}>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim sunt nostrud.
          </p>
          <div style={{ width: '70%', margin: '80px auto' }}>
            <Slider dots items={4} space={80} center={false}>
              <CarouselItem1
                avatar={AvatarImage1}
                name="Stephenie J.Nixon"
                countryCode="ES"
                countryName="Spain"
              />
              <CarouselItem1
                avatar={AvatarImage2}
                name="Garland Lamare"
                countryCode="FR"
                countryName="France"
              />
              <CarouselItem1
                avatar={AvatarImage3}
                name="Charlie Barber"
                countryCode="GB"
                countryName="United Kingdom"
              />
              <CarouselItem1
                avatar={AvatarImage4}
                name="James Cutter"
                countryCode="US"
                countryName="United States"
              />
              <CarouselItem1
                avatar={AvatarImage1}
                name="Stephenie J.Nixon"
                countryCode="ES"
                countryName="Spain"
              />
              <CarouselItem1
                avatar={AvatarImage2}
                name="Garland Lamare"
                countryCode="FR"
                countryName="France"
              />
              <CarouselItem1
                avatar={AvatarImage3}
                name="Charlie Barber"
                countryCode="GB"
                countryName="United Kingdom"
              />
              <CarouselItem1
                avatar={AvatarImage4}
                name="James Cutter"
                countryCode="US"
                countryName="United States"
              />
            </Slider>
          </div>
        </div> */}

          <div className="d-flex justify-content-center align-items-start" style={{ padding: '120px 0', width: '100%' }}>
            <div className="d-flex flex-column align-items-start" style={{ width: '20%', marginRight: 50 }}>
              <div className="d-flex flex-column align-items-start">
                <span style={{ fontSize: 38, fontWeight: 'bold', color: '#07122E', textAlign: 'center' }}>What People Says</span>
                <div style={{ width: 60, paddingBottom: 15, borderBottom: '2px solid #FC9C52', }}></div>
              </div>
              <p style={{ maxWidth: 445, fontSize: 22, fontWeight: 400, color: '#6B6C72', marginTop: 30 }}>
                what our users say.
              </p>
            </div>

            <div style={{ width: '40%', }}>
              <Slider dots items={2} space={25} center={false}>
                <CarouselItem4
                  avatar={AvatarImage1}
                  name="Elizabeth Osteen"
                  job="Fitness Trainer"
                  rating={5}
                  description="Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."
                />
                <CarouselItem4
                  avatar={AvatarImage2}
                  name="Terence Baker"
                  job="Food Blogger"
                  rating={5}
                  description="Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."
                />
                <CarouselItem4
                  avatar={AvatarImage3}
                  name="Stephenie J. Nixon"
                  job="Fitness Trainer"
                  rating={5}
                  description="Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."
                />
              </Slider>
            </div>
          </div>

          <div ref={expertRef} className="d-flex flex-column align-items-center" style={{ marginTop: 120, width: '100%' }}>
            <div className="d-flex justify-content-center" style={{ width: 60, paddingBottom: 15, borderBottom: '2px solid #FC9C52', }}>
              <span style={{ whiteSpace: 'nowrap', fontSize: 38, fontWeight: 'bold', color: '#07122E', textAlign: 'center' }}>Our Experts</span>
            </div>
            <p style={{ maxWidth: 445, marginTop: 20, fontSize: 16, fontWeight: 400, color: '#6B6C72', }}>
              Take a look of what our professionals recommended to you.
            </p>

            <div style={{ width: '70%', margin: '80px auto' }}>
              <Slider items={3} space={50} center={false}>
                <CarouselItem2
                  avatar={AvatarImage5}
                  name="Reginald S. King"
                  job="Nutritionist"
                />
                <CarouselItem2
                  avatar={AvatarImage6}
                  name="Karen Brock"
                  job="Doctor"
                />
                <CarouselItem2
                  avatar={AvatarImage7}
                  name="Carlos Mosher"
                  job="Nutritionist"
                />
                <CarouselItem2
                  avatar={AvatarImage5}
                  name="Reginald S.
                 King" job="Nutritionist"
                />
                <CarouselItem2
                  avatar={AvatarImage6}
                  name="Karen Brock"
                  job="Doctor"
                />
                <CarouselItem2
                  avatar={AvatarImage7}
                  name="Carlos Mosher"
                  job="Nutritionist"
                />
              </Slider>
            </div>
          </div>
        </div>

        <div id="hungry-for-insights" ref={hungryRef} className="d-flex flex-column align-items-center" style={{ width: '100%', backgroundImage: `url(${DotsImage2})`, backgroundRepeat: 'no-repeat', backgroundPositionX: '100%', backgroundPositionY: 400 }}>
          <div className="d-flex flex-column align-items-center" style={{ width: '100%', backgroundColor: '#F5F8FE', padding: '120px 0' }}>
            <div className="d-flex flex-column align-items-center">
              <span style={{ maxWidth: 540, fontSize: 38, fontWeight: 'bold', color: '#07122E', textAlign: 'center' }}>Hungry for Insights and Inspiration?</span>
              <div style={{ width: 60, paddingBottom: 15, borderBottom: '2px solid #FC9C52', }}></div>
            </div>
            <p style={{ maxWidth: 445, marginTop: 20, fontSize: 16, fontWeight: 400, color: '#6B6C72', textAlign: 'center', }}>
              Be updated with the latest contents about nutrition. Scientific and opinion articles, interviews and much more.
            </p>

            <div style={{ width: '90%', marginTop: 80, }}>
              <Slider dots items={3} space={-150} center={true}>
                <CarouselItem3
                  movie='https://soundcloud.com/miami-nights-1984/accelerated'
                  title="Mastering  food cravings – Stephanie"
                  description="Do eiusmod tempor incididunt ut labore et dolore magna aliqua oleo hobe dolor sedo kate pore" />
                <CarouselItem3
                  movie='https://soundcloud.com/miami-nights-1984/accelerated'
                  title="Mastering  food cravings – Stephanie"
                  description="Do eiusmod tempor incididunt ut labore et dolore magna aliqua oleo hobe dolor sedo kate pore" />
                <CarouselItem3
                  movie='https://soundcloud.com/miami-nights-1984/accelerated'
                  title="Mastering  food cravings – Stephanie"
                  description="Do eiusmod tempor incididunt ut labore et dolore magna aliqua oleo hobe dolor sedo kate pore" />
                <CarouselItem3
                  movie='https://soundcloud.com/miami-nights-1984/accelerated'
                  title="Mastering  food cravings – Stephanie"
                  description="Do eiusmod tempor incididunt ut labore et dolore magna aliqua oleo hobe dolor sedo kate pore" />
              </Slider>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
};

export default LandingPage;
