import React, { useEffect, useState } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { BeatLoader } from "react-spinners";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { RootRoutes } from './routes';
import theme from './theme';
import {
  getFieldList,
  getCountries,
  getCuisineList,
  getVegetarianTypeList,
  getDifficultyLevelList,
  getLanguages,
  getMealLevels,
  getGroceryTypes,
} from './store/app/actions';
import { federatedLoginUser, getProfile } from './store/session/actions';
import { awsConfig } from './config/aws-export';
import { history } from './store/history';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-responsive-modal/styles.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import "./assets/css/custom.css"

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

// Amplify.configure(awsConfig);
const [localRedirectSignIn, productRedirectSignIn] = awsConfig.Auth.oauth.redirectSignIn.split(",");
const [localRedirectSignOut, productRedirectSignOut] = awsConfig.Auth.oauth.redirectSignOut.split(",");
const updateAwsConfig = {
  ...awsConfig,
  Auth: {
      ...awsConfig.Auth,
      oauth: {
        ...awsConfig.Auth.oauth,
        redirectSignIn: isLocalhost ? localRedirectSignIn : productRedirectSignIn,
        redirectSignOut: isLocalhost ? localRedirectSignOut : productRedirectSignOut,
    }
  }
}
Amplify.configure(updateAwsConfig);


const App = () => {
  const dispatch = useDispatch();

  const fields = useSelector(state => state.app.fields);
  const countries = useSelector(state => state.app.countries);
  // const contents = useSelector(state => state.app.contents);
  const cuisines = useSelector(state => state.app.cuisines);
  const vegetarianTypes = useSelector(state => state.app.vegetarianTypes);
  const difficultyLevels = useSelector(state => state.app.difficultyLevels);
  const languages = useSelector(state => state.app.languages);
  const language = useSelector(state => state.app.language);

  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch(getFieldList());
    dispatch(getProfile());
    dispatch(getLanguages());
    dispatch(getCountries());
    dispatch(getCuisineList());
    dispatch(getVegetarianTypeList());
    dispatch(getDifficultyLevelList());
    dispatch(getMealLevels());
  }, []);

  useEffect(() => {
    dispatch(getVegetarianTypeList());
    dispatch(getMealLevels());
    dispatch(getGroceryTypes());
    dispatch(getCuisineList());
    dispatch(getDifficultyLevelList());
  }, [languages, language]);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          console.log("information =>", event, data);
          getUser().then(cognitoUser => dispatch(federatedLoginUser(cognitoUser)));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);

  const getUser = async () => {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }


  if (!fields || !countries || !cuisines || !vegetarianTypes || !difficultyLevels) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color='#FC9C52' size={20} margin={5} />
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter>
          <Router history={history}>
            <RootRoutes />
          </Router>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
