import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Topbar, Footer } from './components';

const Main = props => {
  const { children } = props;

  return (
    <div>
      <Topbar />
      <main style={{ minHeight: 200, }}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
