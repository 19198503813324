import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { RiSendPlaneFill } from 'react-icons/ri';

// import { FaMapMarkerAlt } from 'react-icons/fa';
// import { FaPhoneAlt } from 'react-icons/fa';
// import { FaTelegramPlane } from 'react-icons/fa';
// import { BsArrowRight } from 'react-icons/bs';
// import { FaPinterestP } from 'react-icons/fa';
// import { FaFacebookF } from 'react-icons/fa';
// import { FaLinkedinIn } from 'react-icons/fa';
// import { FaTwitter } from 'react-icons/fa';

import {
  SubscribeBar,
  // SocialButton,
  // FooterLink,
  // Slider,
  // CarouselItem5,
} from '../../../../components';

import logoImage from '../../../../assets/image/logo.png';
import FooterImage from '../../../../assets/image/footer.png';
import DotsImage2 from '../../../../assets/image/background-dots2.png';
// import AvatarImage1 from '../../../../assets/image/avatar1.png';
// import AvatarImage2 from '../../../../assets/image/avatar2.png';
// import AvatarImage3 from '../../../../assets/image/avatar3.png';
// import AvatarImage4 from '../../../../assets/image/avatar4.png';
// import AvatarImage8 from '../../../../assets/image/avatar8.png';
// import PosterImage1 from '../../../../assets/image/poster1.png';
// import PosterImage2 from '../../../../assets/image/poster2.png';
// import PosterImage3 from '../../../../assets/image/poster3.png';


const Footer = (props) => {

  const location = useLocation();

  return (
    <div>
      {/* <div className="d-flex flex-column align-items-center text-center" style={{ padding: '120px 0', backgroundColor: '#F5F8FE' }}>
        <div className="d-flex flex-column align-items-center text-center" style={{ width: '100%', backgroundImage: `url(${DotsImage2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'left top' }}>
          <div className="d-flex justify-content-center" style={{ width: 60, paddingBottom: 15, borderBottom: '2px solid #FC9C52', }}>
            <span style={{ whiteSpace: 'nowrap', fontSize: 38, fontWeight: 'bold', color: '#07122E', textAlign: 'center' }}>Recent Blog</span>
          </div>
          <p style={{ maxWidth: 540, marginTop: 20, fontSize: 16, fontWeight: 400, color: '#6B6C72', }}>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim sunt nostrud.
          </p>

          <div style={{ width: '75%', margin: '80px auto 0 auto' }}>
            <Slider dots items={3} space={50} center={false}>
              <CarouselItem5
                image={PosterImage1}
                avatar={AvatarImage2}
                title="Lorem ipsum dolor amet oleo consectetur adipiscing"
                name="Stephenie Nixon"
                date="Sep 26, 2020"
                description="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim"
              />
              <CarouselItem5
                image={PosterImage2}
                avatar={AvatarImage8}
                title="Amet minim mollit non deserunt ullamco est sit aliqua dolor"
                name="Garland Lamare"
                date="Sep 26, 2020"
                description="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim"
              />
              <CarouselItem5
                image={PosterImage3}
                avatar={AvatarImage3}
                title="Velit officia consequat duis enim sunt nostrud oleo hone beo"
                name="Charlie Barber"
                date="Sep 26, 2020"
                description="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim"
              />
              <CarouselItem5
                image={PosterImage1}
                avatar={AvatarImage4}
                title="Lorem ipsum dolor amet oleo consectetur adipiscing"
                name="James Cutter"
                date="Sep 26, 2020"
                description="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim"
              />
            </Slider>
          </div>
        </div>
      </div> */}

      <div style={{ width: '100%', height: 840 }}>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: 530, width: '100%', backgroundImage: `url(${FooterImage})`, backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', }}>
          <div className="d-flex justify-content-center" style={{ width: 60, paddingBottom: 15, borderBottom: '2px solid #FC9C52', }}>
            <span style={{ whiteSpace: 'nowrap', fontSize: 38, fontWeight: 'bold', color: '#07122E', textAlign: 'center' }}>Subscribe Newsletter</span>
          </div>
          <span className="text-center" style={{ width: 540, marginTop: 30, fontSize: 18, fontWeight: 400, color: '#6B6C72' }}>
            Subscribe to receive our latest promotions and articles.
          </span>

          <SubscribeBar />
        </div>
        <div style={{ backgroundColor: '#07122E', paddingTop: 120, }}>
          <div style={{ maxWidth: 1100, margin: 'auto', padding: '0 5%' }}>
            <div className="d-flex flex-column align-items-center" style={{ paddingBottom: 60, borderBottom: '1px solid #202a43' }}>
              {/* <div>
                <div className="footer-menu">
                  <img src={logoImage}></img>
                  <span style={{ color: 'white', fontSize: 20, fontWeight: 400, marginLeft: 5, }}>My Perfect Meal</span>
                </div>
                <p style={{ maxWidth: 300, fontSize: 32, fontWeight: 700, color: 'white' }}>
                  90.82k+ People Are Satisfied With Us
                </p>
                <div className="d-flex justify-content-between" style={{ width: 180, }}>
                  <SocialButton>
                    <FaFacebookF color="white" />
                  </SocialButton>
                  <SocialButton>
                    <FaTwitter color="white" />
                  </SocialButton>
                  <SocialButton>
                    <FaLinkedinIn color="white" />
                  </SocialButton>
                  <SocialButton>
                    <FaPinterestP color="white" />
                  </SocialButton>
                </div>
              </div> */}

              {/* <div style={{ width: 150 }}>
                <p className="footer-menu" style={{ fontSize: 18, fontWeight: 700, color: 'white' }}>
                  About
                </p>
                <FooterLink to="#aboutus">
                  About Us
                </FooterLink>
                <FooterLink to="#aboutus">
                  Our Expert
                </FooterLink>
                <FooterLink to="#aboutus">
                  How it Works
                </FooterLink>
              </div> */}

              {/* <div style={{ width: 175 }}>
                <p className="footer-menu">
                  Resources
                </p>
                <div className="d-flex align-items-start" style={{ width: '100%' }}>
                  <div style={{ width: '45%' }}>
                    <FooterLink to="#aboutus">
                      Blog
                    </FooterLink>
                    <FooterLink to="#aboutus">
                      Terms
                    </FooterLink>
                    <FooterLink to="#aboutus">
                      Privacy
                    </FooterLink>
                    <FooterLink to="#aboutus">
                      Sign Up
                    </FooterLink>
                  </div>
                  <div style={{ width: '55%' }}>
                    <FooterLink to="#aboutus">
                      FAQS
                    </FooterLink>
                    <FooterLink to="#aboutus">
                      Sitemap
                    </FooterLink>
                  </div>
                </div>
              </div> */}

              {/* <div>
                <p className="footer-menu">
                  Contact Us
                </p>
                <div>
                  <p className="d-flex align-items-center">
                    <FaPhoneAlt style={{ fontSize: 12, color: "#3490DD" }} />
                    <span style={{ fontSize: 14, fontWeight: 400, color: 'white', marginLeft: 10 }}>Hotline: 5678 9845 656</span>
                  </p>
                  <p className="d-flex align-items-center">
                    <FaTelegramPlane style={{ fontSize: 12, color: "#3490DD" }} />
                    <span style={{ fontSize: 14, fontWeight: 400, color: 'white', marginLeft: 10 }}>Support: myperfectmeal@gmail.com</span>
                  </p>
                  <p className="d-flex align-items-between">
                    <FaMapMarkerAlt style={{ fontSize: 12, color: "#3490DD", marginTop: 5 }} />
                    <span style={{ maxWidth: 212, fontSize: 14, fontWeight: 400, color: 'white', marginLeft: 10, }}>Address: 935 Allen Way, Yuba City CA, USA.</span>
                  </p>
                </div>
              </div> */}
              <div className="mb-3">
                <img src={logoImage}></img>
                <span style={{ color: 'white', fontSize: 20, fontWeight: 400, marginLeft: 5, }}>My Perfect Meal</span>
              </div>
              <div className="d-flex align-items-center">
                <RiSendPlaneFill style={{ fontSize: 24, color: '#3490DD', marginRight: 5 }} />
                <span style={{ color: '#6B6C72', fontSize: 18, }}>mpm@myperfectmeal.org</span>
              </div>
            </div>
            <div className="d-flex justify-content-between" style={{ padding: '30px 10%', color: '#D3D8E4', opacity: 0.75, }}>
              <span style={{ marginRight: 94 }}>© My Perfect Meal 2020. All Rights Reserved</span>
              <div>
                <span style={{ marginRight: 28 }}>Get Support</span>
                <span style={{ marginRight: 28 }}>Contact Us</span>
                <span>Advertise</span>
              </div>
              {/* <div className="d-flex align-items-center">
                <span style={{ fontSize: 20, fontWeight: 600, color: 'white', marginRight: 10 }}>
                  Calorie Calculator
                </span>
                <BsArrowRight style={{ fontSize: 40, marginRight: 30, color: 'white' }} />
              </div> */}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end" style={{ width: 50, position: 'relative', top: -730, right: 'calc(-100% + 50px)', opacity: 0.4 }}>
          <div style={{ width: '100%', height: 400, backgroundImage: `url(${DotsImage2})`, backgroundRepeat: 'no-repeat', backgroundPositionX: '100%', backgroundPositionY: '100%', zIndex: 1 }}></div>
        </div>
      </div>

    </div >
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
