import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { showToastr } from '../../../services/themeService';
import {
  Button,
  LineEdit,
} from "../../../components";
import { history } from '../../../store/history';


const ResetPassword = (props) => {

  const [code, setCode] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const handleReset = async (e) => {
    e.preventDefault();
    if (pass != confirmPass) {
      showToastr('error', 'Invalid Request', 'Password confirmation doesn\'t match Password');

      return;
    }

    try {
      const email = props.location.state.email;
      const data = await Auth.forgotPasswordSubmit(email, code, pass);
      console.log(data);
      history.push('/sign-in');
    } catch (error) {
      showToastr('error', error.name, error.message);
      console.log(error);
    }
  }


  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead text-center">Enter code and password to reset your password.</p>
      </div>

      <div style={{ maxWidth: 400, margin: 'auto', textAlign: 'center', }}>
        <form className="m-sm-4" onSubmit={handleReset}>
          <LineEdit
            type="text"
            name="code"
            placeholder="Enter code"
            value={code}
            onChange={val => setCode(val)}
          />
          <LineEdit
            type="password"
            name="pass"
            placeholder="Enter your password"
            value={pass}
            onChange={val => setPass(val)}
          />
          <LineEdit
            type="password"
            name="confirm-pass"
            placeholder="Confirm password"
            value={confirmPass}
            onChange={val => setConfirmPass(val)}
          />
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={150}
            height={36}
            style={{ margin: 'auto', marginTop: 30, }}
            onClick={handleReset}
          >
            Reset password
          </Button>
        </form>
      </div>
    </React.Fragment>
  );
}


export default ResetPassword;
