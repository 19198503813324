import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from '../components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from '../layouts';

import {
  LandingPage,
  Votes,
  HungryforInsights,
  HowItWorks,
  OurExperts,
  AboutUs,
  VegetarianFoods,
  VegetarianFoodDetails,
  PreferencesList,
  PreferencesEdit,
  PreferencesWizard,
  WeeklyPlans,
  Groceries,
} from '../views/Main';


const MainRoutes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <RouteWithLayout
        component={LandingPage}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={LandingPage}
        exact
        layout={MainLayout}
        path="/how-it-works"
      />
      <RouteWithLayout
        component={LandingPage}
        exact
        layout={MainLayout}
        path="/hungry-for-insights"
      />
      <RouteWithLayout
        component={LandingPage}
        exact
        layout={MainLayout}
        path="/our-experts"
      />
      <RouteWithLayout
        component={LandingPage}
        exact
        layout={MainLayout}
        path="/about-us"
      />
      <RouteWithLayout
        component={VegetarianFoods}
        exact
        layout={MainLayout}
        path="/vegetarian-foods"
      />
      <RouteWithLayout
        component={VegetarianFoodDetails}
        exact={true}
        layout={MainLayout}
        path="/vegetarian-food/detail/:id?"
      />
      <RouteWithLayout
        component={Votes}
        exact={false}
        layout={MainLayout}
        path="/vegetarian-food/detail/:id?/votes"
      />
      <RouteWithLayout
        component={PreferencesList}
        exact
        layout={MainLayout}
        path="/preferences/list"
      />
      <RouteWithLayout
        component={PreferencesEdit}
        exact
        layout={MainLayout}
        path="/preferences/edit"
      />
      <RouteWithLayout
        component={PreferencesWizard}
        exact
        layout={MinimalLayout}
        path="/preferences/wizard"
      />
      <RouteWithLayout
        component={WeeklyPlans}
        exact
        layout={MainLayout}
        path="/weekly-plans"
      />
      <RouteWithLayout
        component={Groceries}
        exact
        layout={MainLayout}
        path="/groceries"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default MainRoutes;
