import React from 'react';
import ReactPlayer from 'react-player';

import { AiFillCaretRight } from 'react-icons/ai';


const CarouselItem3 = (props) => {
  const { movie, title, description, style, children } = props;

  return (
    <div className="d-flex flex-column align-items-center text-center" style={{ padding: '0 12%', }}>
      <div className='player-wrapper'>
        <ReactPlayer light className="react-player" width='100%' height="100%" style={{ paddingTop: '10%' }} url={movie} />
      </div>
      <span style={{ fontSize: 18, fontWeight: 700, color: '#6B6C72', margin: '20px 0 10px 0' }}>{title}</span>
      <span style={{ fontSize: 12, color: '#B8BABD', }}>{description}</span>
    </div>
  );
}

export default CarouselItem3;