import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from '../components';
import { Minimal as MinimalLayout } from '../layouts';

import {
  NotFound as NotFoundView
} from '../views';

import {
  SignIn as SignInView,
  SignUp as SignUpView,
  ForgotPass as ForgotPassView,
  ResetPass as ResetPassView,
  ConfirmUser as ConfirmUserView,
  TermsService as TermsServiceView,
} from '../views/Auth';

import MainRoutes from './MainRoutes';
import { shallowEqual, useSelector } from "react-redux";

const RootRoutes = () => {

  const { isAuthorized } = useSelector(
    ({ session }) => ({
      isAuthorized: (session.user == null || (Object.keys(session.user).length === 0 && session.user.constructor === Object)) ? false : true,
    }),
    shallowEqual
  );

  return (
    <Switch>
      <RouteWithLayout
        component={SignInView}
        exact={true}
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={SignUpView}
        exact={true}
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={ForgotPassView}
        exact={true}
        layout={MinimalLayout}
        path="/forgot-pass"
      />
      <RouteWithLayout
        component={ResetPassView}
        exact={true}
        layout={MinimalLayout}
        path="/reset-pass"
      />
      <RouteWithLayout
        component={ConfirmUserView}
        exact={true}
        layout={MinimalLayout}
        path="/confirm-user"
      />
      <RouteWithLayout
        component={TermsServiceView}
        exact={true}
        layout={MinimalLayout}
        path="/terms-service"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact={true}
        layout={MinimalLayout}
        path="/not-found"
      />
      {/* {!isAuthorized ? (
        <Redirect
          to="/sign-in"
        />
      ) : ( */}
      <MainRoutes />
      {/* )} */}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default RootRoutes;
