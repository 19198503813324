import React, { useState, useEffect, } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Rating from 'react-rating';
import { FaStar } from 'react-icons/fa';
import { FiStar, FiBookmark } from 'react-icons/fi';
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { BsArrowLeft } from 'react-icons/bs';

import { addWeeklyPlan, updatePrefer, updateVoting, } from '../../../store/app/actions'
import { history } from '../../../store/history';

import {
  Button,
  PieChart,
  TextArea,
  Calendar,
  Dialog,
} from '../../../components';
import IngredientElementItem from './IngredientElementItem';
import InstructionStepItem from './InstructionStepItem';
import { getRecipe, } from '../../../store/app/actions';

import PrepImage from '../../../assets/image/prep.png';
import CookImage from '../../../assets/image/cook.png';
import ActImage1 from '../../../assets/image/act-1.png';
import PreferIcon from '../../../assets/image/act-3.png';
import PlanIcon from '../../../assets/image/meal-planner.png';
import GroceriesIcon from '../../../assets/image/groceries.png';
import WeeklyPlanImage from '../../../assets/image/weekly-plan.png';

import StroberiImage from '../../../assets/image/tmp/stroberi.png';
import BroccoliImage from '../../../assets/image/tmp/broccoli.png';
import MangoImage from '../../../assets/image/tmp/mango.png';
import FoodImage1 from '../../../assets/image/tmp/food-1.png';
import { useStoryblok } from '../../../services/storyblokService';


const colorGroup = [
  '#4CC0B5',
  '#FBAD63',
  '#000000',
];

const imageGroup = [
  StroberiImage,
  BroccoliImage,
  MangoImage,
];

const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

const VegetarianFoodDetails = (props) => {

  const location = useLocation();
  const routes = location.pathname.split('/');
  const id = parseInt(routes[routes.length - 1]);

  const dispatch = useDispatch();

  const preferences = useSelector(state => state.app.preferences);
  const recipeInfo = useSelector(state => state.app.recipeInfo);
  const inRecipe = useSelector(state => state.app.recipe);
  const cuisines = useSelector(state => state.app.cuisines);
  const vegetarianTypes = useSelector(state => state.app.vegetarianTypes);
  const difficultyLevels = useSelector(state => state.app.difficultyLevels);
  const language = useSelector(state => state.app.language);
  const languages = useSelector(state => state.app.languages);
  const mealLevels = useSelector(state => state.app.mealLevels);

  const profile = useSelector(state => state.session.profile);

  const [votingOpen, setVotingOpen] = useState(false);
  const [planOpen, setPlanOpen] = useState(false);

  const [recipe, setRecipe] = useState(null);

  const [preferred, setPreferred] = useState(recipe?.preferred);
  const [rating, setRating] = useState(0);
  const [text, setText] = useState('');
  const [mealTime, setMealTime] = useState({});

  // const language = profile?.langId ?? 1;
  const storyRecipeFields = useStoryblok("recipe-multilingue-fields", true);

  useEffect(() => {
    let loadRecipe = recipeInfo == undefined;
      if (!loadRecipe) {
        let recipe = recipeInfo.meals.find(recipe => recipe.id == id);
        if (recipe === undefined)
          loadRecipe = true;
        else {
          loadRecipe = !recipe.ingredients?.length || !recipe.instructions?.length;
        }
      }
    if (loadRecipe || (preferences && preferences.meals.find(recipe => recipe.id == id) === undefined)) {
      dispatch(getRecipe(id));
    }
  }, []);


  useEffect(() => {
    let meal;
    if (recipeInfo)
      meal = recipeInfo.meals.find(recipe => recipe.id == id);
    if ((!meal || !meal.ingredients?.length || !meal.instructions?.length) && preferences)
      meal = preferences.meals.find(recipe => recipe.id == id);
    if (!meal || !meal.ingredients?.length || !meal.instructions?.length)
      meal = inRecipe;
    setRecipe(meal);
  }, [recipeInfo, preferences, inRecipe]);

  useEffect(() => {
    if (!recipe)
      return;

    setPreferred(recipe.preferred);

    const voteInfo = recipe.votes.comments.find(vote => vote.isOwn);
    if (voteInfo !== undefined) {
      setRating(voteInfo.star);
      setText(voteInfo.comment);
    }
  }, [recipe]);

  const handlePreference = () => {
    setPreferred(!preferred);

    dispatch(updatePrefer(recipe.id, !preferred));
  }

  const languageId = (language) => {
    if (language === null)
      return 1;
    const index = Object.keys(languages).find(key => languages[key]["code"] == language["value"]) ?? 1;
    return index;
  }

  const handleVoting = () => {
    setVotingOpen(true);
  }

  const handleApply = () => {
    dispatch(updateVoting(recipe.id, rating, text));

    setVotingOpen(false);
  }

  const handleClose = () => {
    setVotingOpen(false);
  }

  const handleWeeklyPlan = () => {
    setPlanOpen(true);
  }

  const handleApplyPlan = () => {
    if (Object.keys(mealTime).length === 0)
      return;

    dispatch(addWeeklyPlan(recipe.id, mealTime.date, mealTime.time, 1));

    setMealTime({});
    setPlanOpen(false);
  }

  const handleClosePlan = () => {
    setMealTime({});
    setPlanOpen(false);
  }

  const handleChangeMealTime = (date, mealTime) => {
    setMealTime({
      date: date,
      time: mealTime,
    });
  }

  if (!recipe || !recipe.ingredients?.length || !recipe.instructions?.length) {
    console.log(recipe);
    return (
      <GridLoader color='#FC9C52' loading={true} css={override} size={15} margin={2} />
    );
  }

  return (
    <React.Fragment>
      <div style={{ backgroundColor: '#F5F8FE', padding: '60px 0', }}>
        <div style={{ maxWidth: 1110, margin: 'auto', padding: 50, backgroundColor: 'white' }}>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={30}
            height={30}
            style={{ borderRadius: 15, padding: 0, marginBottom: 20, }}
            onClick={() => history.goBack()}
          >
            <BsArrowLeft style={{ fontSize: 24, height: 20, color: 'white', paddingRight: 5, }} />
          </Button>
          <div className="d-flex align-items-center mb-5">
            <div>
              <img
                src={recipe.image ?? FoodImage1}
                className="rounded"
                alt="Placeholder"
                style={{ borderRadius: 6, maxHeight: 200, }}
              />
              <Link to={{ pathname: `/vegetarian-food/detail/${recipe.id}/votes`, from: 'detail' }} className="d-flex justify-content-between" style={{ display: 'block', marginTop: 10, }}>
                <Rating
                  emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                  fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                  initialRating={recipe.votes.voteStar}
                  readonly
                />
                {`${storyRecipeFields?.content?.Votes ?? 'Votes'}: ${recipe.votes.voteCount}`}
              </Link>
            </div>
            <div className="ml-5" style={{ flex: 1, }}>
              <p style={{ fontSize: 24, fontWeight: 600, color: 'black', marginBottom: 10, }}>
                {recipe.title[languageId(language)] || recipe.title[1]}
              </p>
              <p style={{ color: '#3490DD', }}>
                {recipe.author ? `${storyRecipeFields?.content?.CreatedBy ?? 'Created by'} ${recipe.author}` : ""}
                {(recipe.author && recipe.submittedBy) ? "  |  " : ""}
                {recipe.submittedBy ? `${storyRecipeFields?.content?.SubmittedBy ?? 'Submitted by'} ${recipe.submittedBy}` : ""}
              </p>
              <span className="d-flex align-items-center mb-2">
                <img
                  src={PrepImage}
                  className="mr-2"
                />
                {`${recipe.preparationTime ?? ' '} mins to prep`}
                <img
                  src={CookImage}
                  className="ml-4 mr-2"
                />
                {`${recipe.cookingTime ?? ' '} mins to cook`}
              </span>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="mb-1">
                    {`${storyRecipeFields?.content?.Serves ?? 'Serves'}: ${recipe.serves ?? '0'}`}
                  </p>
                  <p className="mb-1">
                    {`${storyRecipeFields?.content?.Cuisine ?? 'Cuisine'}:  ${cuisines[recipe.cuisineId]?.name ?? ''}`}
                  </p>
                  <p className="mb-1">
                    {`${storyRecipeFields?.content?.VegeterianType ?? 'Vegetarian Type'}:  ${vegetarianTypes[recipe.vegetarianTypeId]?.name ?? ''}`}
                  </p>
                  <p className="mb-1">
                    {`${storyRecipeFields?.content?.Difficulty ?? 'Difficulty Level'}:  ${difficultyLevels[recipe.difficultyLevelId]?.name ?? ''}`}
                  </p>
                </div>
                <div className="ml-5">
                  {
                    recipe.qualities ? (
                      <div>
                        <p className="mb-1">
                          {`${storyRecipeFields?.content?.ProteinComplementarity}: ${mealLevels[recipe.qualities.proteinComplementarity]?.name}`}
                        </p>
                        <p className="mb-1">
                          {`${storyRecipeFields?.content?.IronAbsorption}: ${mealLevels[recipe.qualities.ironAbsortion]?.name}`}
                        </p>
                        <p className="mb-1">
                          {`${storyRecipeFields?.content?.Sulforaphane}: ${mealLevels[recipe.qualities.sulforaphane]?.name}`}
                        </p>
                        <p className="mb-1">
                          {`${storyRecipeFields?.content?.Sustainability}: ${mealLevels[recipe.qualities.sustainability]?.name}`}
                        </p>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ height: 56, margin: '0 0 60px 0', }}>
            <div className="col-2">
              <div className="recipe-action-item" onClick={handleVoting}>
                <img
                  src={ActImage1}
                  alt="Placeholder"
                />
              </div>
            </div>
            {/* <div className="col-2">
              <div className="recipe-action-item">
                <img
                  src={ActImage2}
                  alt="Placeholder"
                />
              </div>
            </div> */}
            <div className="col-2">
              <div className={preferred ? 'recipe-action-item-active' : 'recipe-action-item'} onClick={handlePreference}>
                {
                  <FiBookmark className="pointer" style={{ fontSize: 22, color: 'grey', }} />
                }
              </div>
            </div>
            <div className="col-2">
              <div className="recipe-action-item" onClick={handleWeeklyPlan}>
                <img
                  src={WeeklyPlanImage}
                  alt="Placeholder"
                />
              </div>
            </div>
            {/* <div className="col-2">
              <div className="recipe-action-item">
                <img
                  src={GroceriesIcon}
                  alt="Placeholder"
                />
              </div>
            </div> */}
            {/* <div className="col-2">
              <div className="recipe-action-item">
                <img
                  src={ActImage6}
                  alt="Placeholder"
                />
              </div>
            </div> */}
          </div>
          <div className="row d-flex align-items-start justify-content-between">
            <div className="col-sm-8" style={{ width: 'calc(100% - 200px)', paddingRight: 50, borderRight: '1px solid #DEE2ED', }}>
              <div className="d-flex justify-content-between align-items-center">
                <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black', margin: 0, }}>
                  {storyRecipeFields?.content?.Ingredients}
                </p>
              </div>
              <div style={{ marginBottom: 40, }}>
                {
                  recipe.ingredients.sort((a, b) => (a.position - b.position)).map((ingredient, index) => (
                    <IngredientElementItem
                      key={index}
                      ingredient={ingredient}
                    />
                  ))
                }
              </div>
              <div>
                {
                  recipe.instructions.map((instructionStep, index) => (
                    <InstructionStepItem
                      key={index}
                      step={instructionStep.step}
                      instruction={instructionStep.text[languageId(language)] || instructionStep.text[1]}
                      color={colorGroup[index % colorGroup.length]}
                      stepLabel={storyRecipeFields?.content?.Step}
                    />
                  ))
                }
              </div>
            </div>
            <div className="col-sm-4">
              <PieChart data={recipe.quantityPerPerson} lang={storyRecipeFields?.content}/>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={false}
        open={votingOpen}
        onClose={() => setVotingOpen(false)}
      >
        <div style={{ marginTop: 30, textAlign: 'center' }}>
          <p style={{ fontSize: 18, fontWeight: 600, }}>
            Please leave rating and comment.
          </p>
          <Rating
            emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
            fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
            initialRating={rating}
            onChange={val => setRating(val)}
            style={{ marginBottom: 20, }}
          />
          <br></br>
          <TextArea
            value={text}
            onChange={val => setText(val)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, marginRight: 10, }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, }}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      </Dialog>

      <Dialog
        open={planOpen}
        onClose={() => setPlanOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <div style={{ marginTop: 30, textAlign: 'center' }}>
          <Calendar
            onChangeMealTime={(date, mealTime) => handleChangeMealTime(date, mealTime)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, marginRight: 10, }}
            onClick={handleClosePlan}
          >
            Close
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, }}
            onClick={handleApplyPlan}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default VegetarianFoodDetails;
