import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { FiShoppingCart } from 'react-icons/fi';

import { Button, Calendar } from '../../../components';
import { history } from '../../../store/history';

import { clearWeeklyPlan } from '../../../store/app/actions';
import { ApiService } from '../../../services/apiService';
import { showToastr } from '../../../services/themeService';


const WeeklyPlans = (props) => {

  const dispatch = useDispatch();

  const weeklyPlans = useSelector(state => state.app.weeklyPlans);

  const profile = useSelector(state => state.session.profile);

  const language = profile?.langId ?? 1;

  const [meals, setMeals] = useState(null);

  useEffect(() => {
    if (!weeklyPlans)
      return;

    let meals = [];
    weeklyPlans.map(weeklyPlan => {
      weeklyPlan.meals.map(meal => {
        meals.push({
          id: meal.id,
          amount: meal.amount
        })
      })
    })
    setMeals(meals);
  }, [weeklyPlans]);

  const handleClear = () => {
    ApiService.deleteMyWeeklyPlan()
      .then(data => {
        console.log("data:", data);
        showToastr("info", "Success", "Successfully deleted weekly plan");
        setMeals([]);
        dispatch(clearWeeklyPlan());
      })
      .catch(e => {
        console.log("Error deleting weekly plan:", e);
        showToastr("error", "Error", "An error occurred while deleting the weekly plan");
      })
  }

  const handleGroceries = () => {
    history.push('/groceries', { meals: meals, from: '/weekly-plans' });
  }


  return (
    <div style={{ padding: '0 10%', }}>
      <div className="d-flex justify-content-between align-items-center mb-5" style={{ width: '100%', marginTop: 50, }}>
        <div>
          <p style={{ fontSize: 38, fontWeight: 'bold', }}>
            Weekly Plan
          </p>
          <div style={{ width: 60, borderBottom: '2px solid #FC9C52', }}></div>
        </div>
        <div>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            className="mr-2"
            width={110}
            height={38}
            onClick={handleClear}>
            Clear List
          </Button>
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={175}
            height={38}
            onClick={handleGroceries}
          >
            <FiShoppingCart style={{ fontSize: 16, color: 'white', marginRight: 10, }} />
            Show Groceries
          </Button>
        </div>
      </div>
      <div className="mb-4">
        <Link to="/home" className="mr-2" style={{ color: '#6B6C72', textDecoration: 'none', fontWeight: 600 }}>
          <BsArrowLeft style={{ fontSize: 18, marginRight: 5, }} />Home
        </Link>
        <Link to="/weekly-plans" style={{ color: '#6B6C72', textDecoration: 'none', fontWeight: 600 }}>Weekly Plan</Link>
      </div>
      <Calendar
        editable
      // onChangeWeek={(meals) => handleChangeWeek(meals)}
      />
    </div>
  )
}


export default WeeklyPlans;
