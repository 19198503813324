import React, { useState } from 'react';

import {
  HiOutlineUser,
  HiOutlineMail,
  HiOutlineLockClosed,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi';


const TextArea = (props) => {

  const { id, placeholder, style, value, onChange } = props;


  return (
    <textarea id={id} name={id} rows="4" cols="50" value={value} onChange={(val) => onChange(val.target.value)}>
      {value}
    </textarea>
  );
}

export default TextArea;