import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { RiSendPlaneFill } from 'react-icons/ri';
import store from '../../../../store'

import { Button } from '../../../../components';
import { logoutUser } from '../../../../store/session/actions';
import { history } from '../../../../store/history';

import logoImage from '../../../../assets/image/logo.png';
import { selectLanguage } from '../../../../store/app';


const NavBarItem = (props) => {

  const { children, style, active, onClick, text } = props;

  const className = clsx({
    "nav-bar-active": active,
  });

  return (
    <li style={style} onClick={onClick}>
      <div className={className}>
        {text}
      </div>
      {children}
    </li>
  );
}


const NavBar = (props) => {

  const location = useLocation();

  let index = 0;
  if (location.pathname.includes('/home')) {
    index = 1;
  } else if (location.pathname.includes('/about-us')) {
    index = 2;
  } else if (location.pathname.includes('/how-it-works')) {
    index = 3;
  } else if (location.pathname.includes('/our-expert')) {
    index = 4;
  } else if (location.pathname.includes('/hungry-for-insights')) {
    index = 5;
  }

  return (
    <div className="" style={{ height: 75, backgroundColor: '#EEF7FE', }}>
      <div className="d-flex align-items-center justify-content-between" style={{ maxWidth: 1100, height: '100%', margin: 'auto', }}>
        <Link to="/home" style={{ color: '#6B6C72', textDecoration: 'none', }}>
          <img src={logoImage}></img>
          <span style={{ fontSize: 22, marginLeft: 8, fontWeight: 'bold', }}>My Perfect Meal</span>
        </Link>
        <ul className="nav-bar">
          <NavBarItem
            text="Home"
            active={index === 1 ? true : false}
            onClick={() => history.push('/home')}
          />
          <NavBarItem
            text="How it Works"
            active={index === 3 ? true : false}
            onClick={() => history.push('/how-it-works')}
          />
          <NavBarItem
            text="Our Experts"
            active={index === 4 ? true : false}
            onClick={() => history.push('/our-experts')}
          />
          <NavBarItem
            text="Hungry for insights?"
            active={index === 5 ? true : false}
            onClick={() => history.push('/hungry-for-insights')}
          />
          <NavBarItem
            text="About Us"
            active={index === 2 ? true : false}
            onClick={() => history.push('/about-us')}
          />
        </ul>
      </div>
    </div >
  )
}


const Topbar = (props) => {

  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(state => state.session);
  const languages = useSelector(state => state.app.languages);
  const language = useSelector(state => state.app.language);
  const [ langOpt, setLangOpt ] = useState([]);

  const handleSignOut = () => {
    dispatch(logoutUser());
  }

  const handleOnChange = (value) => {
    dispatch(selectLanguage(value));
  }

  useEffect(() => {
    if (languages)
      setLangOpt(Object.values(languages).map(lang => ({label: lang.name, value: lang.code})));
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between" style={{ maxWidth: 1100, margin: 'auto', height: 64 }}>
        <div className="d-flex align-items-center" style={{ marginRight: 40, fontSize: 14, fontWeight: 400, color: '#6B6C72' }}>
          <RiSendPlaneFill style={{ fontSize: 24, color: '#3490DD', marginRight: 5 }} />
          <span style={{ color: '#6B6C72', fontSize: 18, }}><span style={{ fontWeight: 700 }}>Support: </span>mpm@myperfectmeal.org</span>
        </div>
        <div style={{ width: '20%' }}><Select options={langOpt} value={language} onChange={handleOnChange}></Select></div>

        {
          isAuthenticated ? (
            <div className="d-flex">
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                className="mr-2"
                height={35}
                onClick={() => history.push('/preferences/edit')}
              >
                Edit Profile
              </Button>
              <Button
                variant="contained"
                bgColor="secondary"
                fontColor="white"
                className="mr-2"
                height={35}
                onClick={() => history.push('/preferences/list')}
              >
                My List
              </Button>
              <Button
                variant="contained"
                bgColor="secondary"
                fontColor="white"
                className="mr-2"
                height={35}
                onClick={() => history.push('/weekly-plans')}
              >
                Weekly Plan
              </Button>
              <Button
                variant="contained"
                height={35}
                onClick={() => handleSignOut()}
              >
                Log out
              </Button>
            </div>
          )
            : (
              <div className="d-flex">
                <Button
                  variant="contained"
                  className="mr-2"
                  height={35}
                  onClick={() => history.push('/sign-in')}
                >
                  Log in
                </Button>
                <Button
                  variant="contained"
                  bgColor="primary"
                  fontColor="white"
                  height={35}
                  onClick={() => history.push('/sign-up')}
                >
                  Sign up
                </Button>
              </div>
            )
        }

      </div >

      <NavBar />
    </div >
  );
}

export default Topbar;
