import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StoryblokClient from "storyblok-js-client";
 
const Storyblok = new StoryblokClient({
  accessToken: "Z621f1nlepsmJNp4sYMPEgtt",
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export function useStoryblok(slug, preview) {
    const [story, setStory] = useState(null);
    const language = useSelector(state => state.app.language);

    function initEventListeners() {
        const { StoryblokBridge } = window;
        if (typeof StoryblokBridge !== "undefined") {
            const storyblokInstance = new StoryblokBridge({});

            storyblokInstance.on(["change", "published"], () => {
                window.location.reload(true);
            });

            storyblokInstance.on("input", (event) => {
                setStory(event.story);
            });

            storyblokInstance.on("enterEditmode", (event) => {
                Storyblok.get(`cdn/stories/${event.storyId}`, {
                    version: "draft",
                })
                    .then(({data}) => {
                        if (data.story) {
                            setStory(data.story);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    })
            });
        }
    }

    function addBridge(callback) {
        const existingScript = document.getElementById("storyblokBridge");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
            script.id = "storyblokBridge";
            document.body.appendChild(script);
            script.onload = () => {
                callback();
            }
        } else {
            callback();
        }
    }

    useEffect(() => {
        const params = {
            version: "published",
        };

        if (preview) {
            params.version = "published";
            params.language = language ? language.value.toLowerCase() : window.navigator.language || window.navigator.userLanguage;
            params.fallback_lang = "en";
            params.cv = Date.now();
        }

        Storyblok.get(`cdn/stories/${slug}`, params)
            .then((response) => {
                setStory(response.data.story);
            })
            .catch((error) => {
                console.error(error);
            });

        if (preview) {
            addBridge(initEventListeners);
        }
    }, [preview, slug]);

    return story;
}

export default Storyblok;