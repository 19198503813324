import React from 'react';


const InstructionStepItem = (props) => {

  const { id, step, instruction, color, stepLabel } = props;
  let actualStepLabel = stepLabel;

  if (!actualStepLabel)
    actualStepLabel = "Step";

  return (
    <div className="instruction-step-item">
      <div className=" mb-2">
        <p style={{ fontSize: 16, fontWeight: 'bold', color: color, marginBottom: 0, }}>
          {`${actualStepLabel} ${step}`}
        </p>
      </div>
      <p style={{ fontSize: 16, }}>
        {instruction}
      </p>
    </div>
  )
}

export default InstructionStepItem;