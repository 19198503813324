import React, { useState, useEffect, } from 'react';
import {
  AiOutlineSearch,
} from 'react-icons/ai';
import InputRange from 'react-input-range';
import { useDispatch, useSelector } from 'react-redux';
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";

import {
  CheckBox,
  Pagination,
  RecipeItem,
} from '../../../components';
import {
  getRecipeList,
  noneRecipeList,
} from '../../../store/app/actions';

import MainImage from '../../../assets/image/vegetarian-foods.png';
import DotsImage1 from '../../../assets/image/background-dots1.png';
import DotsImage2 from '../../../assets/image/background-dots2.png';
import { updateFilter } from '../../../store/session';


const override = css`
  display: block;
  margin: auto;
`;


const VegetarianFoods = () => {

  const dispatch = useDispatch();

  const recipeInfo = useSelector(state => state.app.recipeInfo);
  const preferences = useSelector(state => state.app.preferences);
  const profile = useSelector(state => state.session.profile);

  const cuisines = useSelector(state => state.app.cuisines);
  const vegetarianTypes = useSelector(state => state.app.vegetarianTypes);
  const difficultyLevels = useSelector(state => state.app.difficultyLevels);
  const filterOptions = useSelector(state => state.session.filterOptions);

  const [page, setPage] = useState(recipeInfo ? recipeInfo.page : 1);

  const [keyword, setKeyword] = useState(filterOptions.keyword);
  const [selectedCuisines, setSelectedCuisines] = useState(filterOptions.cuisines);
  const [selectedVegetarianTypes, setSelectedVegetarianTypes] = useState(filterOptions.vegetarianTypes);
  const [selectedDifficultyLevels, setSelectedDifficultyLevels] = useState(filterOptions.difficultyLevels);
  const [preparationRange, setPreparationRange] = useState(filterOptions.preparationTime.value);
  const [servesRange, setServesRange] = useState(filterOptions.serves.value);
  const [votingRange, setVotingRange] = useState(filterOptions.userStars.value);
  const [selectedEvaluationStars, setSelectedEvaluationStars] = useState(filterOptions.evaluationStars);
  const [energyRange, setEnergyRange] = useState(filterOptions.energy.value);
  const [proteinRange, setProteinRange] = useState(filterOptions.protein.value);
  const [fatRange, setFatRange] = useState(filterOptions.fat.value);
  const [sugarRange, setSugarRange] = useState(filterOptions.sugar.value);
  const [carbohydrateRange, setCarbohydrateRange] = useState(filterOptions.carbohydrate.value);
  const [fiberRange, setFiberRange] = useState(filterOptions.fiber.value);

  const count = 12;
  
  useEffect(() => {
    if (!recipeInfo)
      handlePageChange(1);
  }, []);

  useEffect(() => {
    if (profile) {
      dispatch(updateFilter({ ...filterOptions, cuisines: profile.cuisines.slice(), vegetarianTypes: profile.vegetarianTypes.slice() }));
    }
  }, [profile]);

  const handlePageChange = (val) => {
    try {
      setPage(parseInt(val));
    }
    catch {
    }

    dispatch(noneRecipeList());

    dispatch(getRecipeList(val, count, 'id', 'asc'));
  }

  const handleSearch = (e) => {
    dispatch(updateFilter({ ...filterOptions, keyword: keyword }));

    handlePageChange(1);

    e.preventDefault();
  }

  const handleCuisineCheck = (id) => {
    let tmpCuisines = selectedCuisines.slice();
    if (tmpCuisines.find(tmpCuisine => tmpCuisine === id) === undefined) {
      tmpCuisines.push(id);
    } else {
      const index = tmpCuisines.indexOf(id);
      tmpCuisines.splice(index, 1);
    }

    setSelectedCuisines(tmpCuisines);

    dispatch(updateFilter({ ...filterOptions, cuisines: tmpCuisines }));

    dispatch(noneRecipeList());
    dispatch(getRecipeList(page, count, 'id', 'asc'));
  }

  const handleEvaluationStars = (name) => {
    let tmpEvaluationStars = selectedEvaluationStars.slice();

    if (tmpEvaluationStars.find(tmpVegetarianType => tmpVegetarianType === name) === undefined) {
      tmpEvaluationStars.push(name);
    } else {
      const index = tmpEvaluationStars.indexOf(name);
      tmpEvaluationStars.splice(index, 1);
    }

    setSelectedEvaluationStars(tmpEvaluationStars);

    dispatch(updateFilter({ ...filterOptions, evaluationStars: tmpEvaluationStars }));

    dispatch(noneRecipeList());
    dispatch(getRecipeList(page, count, 'id', 'asc'));
  }

  const handleVegetarianCheck = (id) => {
    let tmpVegetarianTypes = selectedVegetarianTypes.slice();
    if (tmpVegetarianTypes.find(tmpVegetarianType => tmpVegetarianType === id) === undefined) {
      tmpVegetarianTypes.push(id);
    } else {
      const index = tmpVegetarianTypes.indexOf(id);
      tmpVegetarianTypes.splice(index, 1);
    }

    setSelectedVegetarianTypes(tmpVegetarianTypes);

    dispatch(updateFilter({ ...filterOptions, vegetarianTypes: tmpVegetarianTypes }));

    dispatch(noneRecipeList());
    dispatch(getRecipeList(page, count, 'id', 'asc'));
  }

  const handleDifficultyCheck = (id) => {
    let tmpDifficultyLevels = selectedDifficultyLevels.slice();
    if (tmpDifficultyLevels.find(tmpDifficultyLevel => tmpDifficultyLevel === id) === undefined) {
      tmpDifficultyLevels.push(id);
    } else {
      const index = tmpDifficultyLevels.indexOf(id);
      tmpDifficultyLevels.splice(index, 1);
    }

    setSelectedDifficultyLevels(tmpDifficultyLevels);

    dispatch(updateFilter({ ...filterOptions, difficultyLevels: tmpDifficultyLevels }));

    dispatch(noneRecipeList());
    dispatch(getRecipeList(page, count, 'id', 'asc'));
  }

  const handleComplete = (type, value) => {
    if (type === 0) {
      dispatch(updateFilter({ ...filterOptions, preparationTime: { ...filterOptions.preparationTime, value: value } }));
    } else if (type === 1) {
      dispatch(updateFilter({ ...filterOptions, serves: { ...filterOptions.serves, value: value } }));
    } else if (type === 2) {
      dispatch(updateFilter({ ...filterOptions, userStars: { ...filterOptions.userStars, value: value } }));
    } else if (type === 3) {
    //   dispatch(updateFilter({ ...filterOptions, evaluationStars: { ...filterOptions.evaluationStars, value: value } }));
    } else if (type === 4) {
      dispatch(updateFilter({ ...filterOptions, energy: { ...filterOptions.energy, value: value } }));
    } else if (type === 5) {
      dispatch(updateFilter({ ...filterOptions, protein: { ...filterOptions.protein, value: value } }));
    } else if (type === 6) {
      dispatch(updateFilter({ ...filterOptions, fat: { ...filterOptions.fat, value: value } }));
    } else if (type === 7) {
      dispatch(updateFilter({ ...filterOptions, sugar: { ...filterOptions.sugar, value: value } }));
    } else if (type === 8) {
      dispatch(updateFilter({ ...filterOptions, carbohydrate: { ...filterOptions.carbohydrate, value: value } }));
    } else if (type === 9) {
      dispatch(updateFilter({ ...filterOptions, fiber: { ...filterOptions.fiber, value: value } }));
    }

    dispatch(noneRecipeList());
    dispatch(getRecipeList(page, count, 'id', 'asc'));
  }

  while (!cuisines || !vegetarianTypes) {
    return (
      <GridLoader color='#FC9C52' loading={true} css={override} size={15} margin={2} />
      );
    }

  return (
    <div className="d-flex flex-column align-items-center" style={{ width: '100%', backgroundImage: `url(${MainImage})`, backgroundSize: 'auto 529px', backgroundRepeat: 'no-repeat', backgroundPosition: 'top', }}>
      <span className="text-center" style={{ width: 463, marginTop: 120, fontSize: 38, fontWeight: 700 }}>
        Vegetarian Foods
      </span>
      <span className="text-center" style={{ width: 568, marginTop: 30, fontSize: 18, fontWeight: 400 }}>
        A healthy and individualized vegetarian diet just for you! In one click choose your meals and have a weekly menu.
      </span>
      <div className="row" style={{ borderRadius: 6, width: '80%', backgroundColor: 'white', padding: '75px 5%', marginTop: 80, }}>
        <div className="col-3">
          <form className="d-flex align-items-center" style={{ backgroundColor: '#F5F8FE', borderRadius: 6, height: 52, paddingRight: 20, }} onSubmit={e => handleSearch(e)}>
            <input
              placeholder="Search"
              style={{ border: 'none', outline: 'none', paddingLeft: 20, borderRadius: 6, fontSize: 16, width: '100%', height: '100%', color: '#07122E', backgroundColor: '#F5F8FE' }}
              value={keyword}
              onChange={val => setKeyword(val.target.value)}
            />
            <AiOutlineSearch className="pointer" style={{ fontSize: 18, marginRight: 5 }} onClick={handleSearch} />
          </form>
          <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
            Type of cuisines
          </p>
          <div style={{ color: '#6B6C72', fontSize: 16, maxHeight: 150, overflowY: 'scroll', padding: '0 5%', }}>
            {
              cuisines ? Object.entries(cuisines).map((cuisine, index) => (
                <CheckBox
                  key={`cuisine_${index}`}
                  id={`cuisine_${index}`}
                  style={{ fontSize: 16, color: '#6B6C72', margin: '10px 0', }}
                  checked={filterOptions.cuisines.includes(parseInt(cuisine[0]))}
                  onClick={() => handleCuisineCheck(parseInt(cuisine[0]))}
                >
                  {cuisine[1].name}
                </CheckBox>
              )) : null
            }
          </div>

          <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
            Vegetarian types
          </p>
          <div style={{ color: '#6B6C72', fontSize: 16, maxHeight: 150, overflowY: 'scroll', padding: '0 5%', }}>
            {
              vegetarianTypes ? Object.entries(vegetarianTypes).map((vegetarian, index) => (
                <CheckBox
                  key={`vegetarian_${index}`}
                  id={`vegetarian_${index}`}
                  style={{ fontSize: 16, color: '#6B6C72', margin: '10px 0', }}
                  checked={filterOptions.vegetarianTypes.includes(parseInt(vegetarian[0]))}
                  onClick={() => handleVegetarianCheck(parseInt(vegetarian[0]))}
                >
                  {vegetarian[1].name}
                </CheckBox>
              )) : null
            }
          </div>

          <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
            Difficulty levels
          </p>
          <div style={{ color: '#6B6C72', fontSize: 16, maxHeight: 150, overflowY: 'scroll', padding: '0 5%', }}>
            {
              difficultyLevels ? Object.entries(difficultyLevels).map((difficulty, index) => (
                <CheckBox
                  key={`difficulty_${index}`}
                  id={`difficulty_${index}`}
                  style={{ fontSize: 16, color: '#6B6C72', margin: '10px 0', }}
                  checked={filterOptions.difficultyLevels.includes(parseInt(difficulty[0]))}
                  onClick={() => handleDifficultyCheck(parseInt(difficulty[0]))}
                >
                  {difficulty[1].name}
                </CheckBox>
              )) : null
            }
          </div>
          
          <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
            Nutrition
          </p>
          <div style={{ color: '#6B6C72', fontSize: 16, maxHeight: 150, overflowY: 'scroll', padding: '0 5%', }}>
          {
            ["Adequate", "Superb", "Exceptional"].map((nutrition, index) => (
              <CheckBox
                key={`nutrition_${index}`}
                id={`nutrition_${index}`}
                style={{ fontSize: 16, color: '#6B6C72', margin: '10px 0', }}
                checked={filterOptions.evaluationStars.includes(nutrition)}
                onClick={() => handleEvaluationStars(nutrition)}
              >
                {nutrition}
              </CheckBox>
            ))
          }
          </div>

          <div style={{ paddingRight: 20, }}>
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Time of Preparation
            </p>
            <InputRange
              maxValue={filterOptions.preparationTime.range.max}
              minValue={filterOptions.preparationTime.range.min}
              value={preparationRange}
              onChange={value => setPreparationRange(value)}
              onChangeComplete={value => handleComplete(0, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Number of serves per meal
            </p>
            <InputRange
              maxValue={filterOptions.serves.range.max}
              minValue={filterOptions.serves.range.min}
              value={servesRange}
              onChange={value => setServesRange(value)}
              onChangeComplete={value => handleComplete(1, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              User Votes
            </p>
            <InputRange
              maxValue={filterOptions.userStars.range.max}
              minValue={filterOptions.userStars.range.min}
              value={votingRange}
              onChange={value => setVotingRange(value)}
              onChangeComplete={value => handleComplete(2, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Calorie
            </p>
            <InputRange
              maxValue={filterOptions.energy.range.max}
              minValue={filterOptions.energy.range.min}
              value={energyRange}
              onChange={value => setEnergyRange(value)}
              onChangeComplete={value => handleComplete(4, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Protein
            </p>
            <InputRange
              maxValue={filterOptions.protein.range.max}
              minValue={filterOptions.protein.range.min}
              value={proteinRange}
              onChange={value => setProteinRange(value)}
              onChangeComplete={value => handleComplete(5, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Fat
            </p>
            <InputRange
              maxValue={filterOptions.fat.range.max}
              minValue={filterOptions.fat.range.min}
              value={fatRange}
              onChange={value => setFatRange(value)}
              onChangeComplete={value => handleComplete(6, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Sugar
            </p>
            <InputRange
              maxValue={filterOptions.sugar.range.max}
              minValue={filterOptions.sugar.range.min}
              value={sugarRange}
              onChange={value => setSugarRange(value)}
              onChangeComplete={value => handleComplete(7, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Carbohydrate
            </p>
            <InputRange
              maxValue={filterOptions.carbohydrate.range.max}
              minValue={filterOptions.carbohydrate.range.min}
              value={carbohydrateRange}
              onChange={value => setCarbohydrateRange(value)}
              onChangeComplete={value => handleComplete(8, value)}
            />
            <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
              Fiber
            </p>
            <InputRange
              maxValue={filterOptions.fiber.range.max}
              minValue={filterOptions.fiber.range.min}
              value={fiberRange}
              onChange={value => setFiberRange(value)}
              onChangeComplete={value => handleComplete(9, value)}
            />
          </div>
        </div>
        <div className="col-9 m-0">
          {
            recipeInfo ? (
              <div>
                <div className="row m-0">
                  {
                    recipeInfo.meals?.map((recipe, index) => (
                      <div key={index} className="col-lg-4 col-md-6">
                        <RecipeItem
                          recipe={recipe}
                        />
                      </div>
                    ))
                  }
                </div>
                <Pagination
                  value={page ?? 1}
                  maxPage={recipeInfo.pages}
                  onUpdate={(val) => handlePageChange(val)}
                  onChange={setPage}
                />
              </div>
            ) : (
              <div className="">
                <GridLoader color='#FC9C52' loading={true} css={override} size={15} margin={2} />
              </div>
            )
          }
        </div>
      </div>
    </div >
  );
};

export default VegetarianFoods;
