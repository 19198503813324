import React, { useState, } from "react";
import { PieChart as Pie } from 'react-minimal-pie-chart';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import {
  Button,
} from '../../components';


function FullOption(props) {
  const [selected, setSelected] = useState(null);
  const [hovered, setHovered] = useState(null);

  const data = props.data.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: 'grey',
      };
    }
    return entry;
  });

  const lineWidth = 60;

  return (
    <Pie
      style={{
        fontFamily:
          '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
        fontSize: '8px',
      }}
      data={data}
      radius={Pie.defaultProps.radius - 6}
      lineWidth={30}
      startAngle={-90}
      segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
      segmentsShift={(index) => (index === selected ? 5 : 0)}
      paddingAngle={2}
      animate
      label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
      labelPosition={100 - lineWidth / 4}
      labelStyle={{
        fill: '#fff',
        opacity: 0.75,
        fontSize: 5,
        pointerEvents: 'none',
      }}
      onClick={(_, index) => {
        setSelected(index === selected ? undefined : index);
      }}
      onMouseOver={(_, index) => {
        setHovered(index);
      }}
      onMouseOut={() => {
        setHovered(undefined);
      }}
    />
  );
}

const capitalize = (str) => {
  return str.split(" ").map(s => (s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())).join(" ");
}

const PieChart = (props) => {

  const { data, lang } = props;

  const fields = useSelector(state => state.app.fields);

  const [more, setMore] = useState(false);

  const energyId = parseInt(Object.entries(fields).find(field => field[1].name === 'energy_cal')?.[0]);
  const carbId = parseInt(Object.entries(fields).find(field => field[1].name === 'carbohydrate')?.[0]);
  const fatId = parseInt(Object.entries(fields).find(field => field[1].name === 'fat_total')?.[0]);
  const proteinId = parseInt(Object.entries(fields).find(field => field[1].name === 'protein')?.[0]);
  const sugarsId = parseInt(Object.entries(fields).find(field => field[1].name === 'sugars_total')?.[0]);
  const fiberId = parseInt(Object.entries(fields).find(field => field[1].name === 'fiber')?.[0]);

  const energy = Math.round(data?.find(item => item.fieldId === energyId)?.value);
  const carb = parseFloat(data?.find(item => item.fieldId === carbId)?.value?.toFixed(1));
  const fat = parseFloat(data?.find(item => item.fieldId === fatId)?.value?.toFixed(1));
  const protein = parseFloat(data?.find(item => item.fieldId === proteinId)?.value?.toFixed(1));
  const sugars = parseFloat(data?.find(item => item.fieldId === sugarsId)?.value?.toFixed(1));
  const fiber = parseFloat(data?.find(item => item.fieldId === fiberId)?.value?.toFixed(1));

  let other = 0;
  if (data) {
    data.filter(item => item.fieldId !== energyId && item.fieldId !== carbId && item.fieldId !== fatId && item.fieldId !== proteinId && item.fieldId !== sugarsId && item.fieldId !== fiberId)
      .map(item => {
        const unit = fields[item.fieldId].unit;
        const conversion = {
          "g": 1,
          "mg": 1000,
          "mcg": 1000000,
          "µg": 1000000
        };
        other += item.value / conversion[unit];
      })
  }
  other = parseFloat(other.toFixed(1));

  const dataMock = [
    { title: lang?.Carbohydrate ?? 'Carbohydrate', value: carb, color: '#3490DD' },
    { title: lang?.Fat ?? 'Fat', value: fat, color: '#FF7F87' },
    { title: lang?.Protein ?? 'Protein', value: protein, color: '#354052' },
    { title: lang?.Fiber ?? 'Fiber', value: fiber, color: '#418200' },
    { title: lang?.Other ?? 'Other', value: other, color: 'gray' },
  ];

  const field_order = [
    // "protein",
    // "fiber",
    // "carbohydrate",
    // "sugars_total",
    // "fat_total",
    "fatty_acids_saturated",
    "fatty_acids_monounsaturated",
    "fatty_acids_polyunsaturated",
    "vitamin_a_rae",
    "vitamin_c",
    "vitamin_d_d2andd3",
    "vitamin_e",
    "vitamin_k",
    "thiamin",
    "riboflavin",
    "niacin",
    "vitamin_b6",
    "folate",
    "vitamin_b12",
    "calcium",
    "iron",
    "magnesium",
    "phosphorus",
    "selenium",
    "zinc",
    "potassium",
    "sodium"
  ];

  return (
    <div>
      <div className="align-self-center w-100" style={{ position: 'relative', }}>
        <div className="chart chart-md">
          <FullOption data={dataMock} />
        </div>
        <div className="d-inline-block" style={{ position: 'absolute', top: '50%', marginTop: -37, left: '50%', marginLeft: -27, }}>
          <p style={{ fontSize: 16, marginBottom: 5, }}>
            {lang?.Calorie}
          </p>
          <p style={{ fontSize: 30, fontWeight: 'bold', color: '#07122E', marginBottom: 0, textAlign: 'center', }}>
            {energy}
          </p>
        </div>
      </div>

      <div style={{ padding: '0 10%', }}>
        {
          more ? (
            <div style={{ height: 500, overflowY: 'scroll', marginBottom: 15, }}>
              <p>
                <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black', }}>
                  {lang?.Calorie}
                </span>
                <span className="float-right">
                  {energy}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Protein}
                </span>
                <span className="float-right">
                  {protein ? `${protein.toFixed(1)} ${fields[proteinId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#418200', }}>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Fiber}
                </span>
                <span className="float-right">
                  {fiber ? `${fiber.toFixed(1)} ${fields[fiberId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#3490DD', }}>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Carbohydrate}
                </span>
                <span className="float-right">
                  {carb ? `${carb.toFixed(1)} ${fields[carbId].unit}` : ''}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Sugar}
                </span>
                <span className="float-right">
                  {sugars ? `${sugars.toFixed(1)} ${fields[sugarsId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#FF7F87', }}>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Fat}
                </span>
                <span className="float-right">
                  {fat ? `${fat.toFixed(1)} ${fields[fatId].unit}` : ''}
                </span>
              </p>
              {
                /*data.filter(item => item.fieldId !== energyId && item.fieldId !== carbId && item.fieldId !== fatId && item.fieldId !== proteinId)
                  .map((item, index) => (
                    <p key={index} style={{ color: 'gray', }}>
                      <span style={{ fontWeight: 'bold', }}>
                        {fields[item.fieldId].display}
                      </span>
                      <span className="float-right">
                        {`${item.value.toFixed(1)} ${fields[item.fieldId].unit}`}
                      </span>
                    </p>
                  ))*/
                
                field_order.map((field_name, index) => {
                  const [fieldId, field] = Object.entries(fields).find(([k, v]) => v.name == field_name);
                  const value = data.find(f => f.fieldId == fieldId).value;
                  const langKey = capitalize(field.name.replaceAll("_", " ")).replaceAll(" ", "");
                  return (
                    <p key={index} style={{ color: 'gray', }}>
                      <span style={{ fontWeight: 'bold', }}>
                        {lang?.[langKey]}
                      </span>
                      <span className="float-right">
                        {`${value.toFixed(1)} ${field.unit}`}
                      </span>
                    </p>
                  )
                })
              }
            </div>
          ) : (
            <div>
              <p>
                <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black', }}>
                  {lang?.Calorie}
                </span>
                <span className="float-right">
                  {energy}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Protein}
                </span>
                <span className="float-right">
                  {protein ? `${protein.toFixed(1)} ${fields[proteinId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#418200', }}>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Fiber}
                </span>
                <span className="float-right">
                  {fiber ? `${fiber.toFixed(1)} ${fields[fiberId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#3490DD', }}>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Carbohydrate}
                </span>
                <span className="float-right">
                  {carb ? `${carb.toFixed(1)} ${fields[carbId].unit}` : ''}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Sugar}
                </span>
                <span className="float-right">
                  {sugars ? `${sugars.toFixed(1)} ${fields[sugarsId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#FF7F87', }}>
                <span style={{ fontWeight: 'bold', }}>
                  {lang?.Fat}
                </span>
                <span className="float-right">
                  {fat ? `${fat.toFixed(1)} ${fields[fatId].unit}` : ''}
                </span>
              </p>
            </div>
          )
        }
        {
          more ? (
            <Button
              variant="contained"
              bgColor="primary"
              fontColor="white"
              width="100%"
              height={34}
              style={{ marginRight: 30, borderRadius: 100, }}
              onClick={() => setMore(!more)}
            >
              View less
              <AiOutlineUp style={{ fontSize: 14, color: 'white', marginLeft: 10, }} />
            </Button>
          ) : (
            <Button
              variant="contained"
              bgColor="primary"
              fontColor="white"
              width="100%"
              height={34}
              style={{ marginRight: 30, borderRadius: 100, }}
              onClick={() => setMore(!more)}
            >
              View more
              <AiOutlineDown style={{ fontSize: 14, color: 'white', marginLeft: 10, }} />
            </Button>
          )
        }
      </div>
    </div>
  );
};

export default PieChart;
