import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fragment } from 'react';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { BsPencil, } from 'react-icons/bs';
import { BiTrash, BiCalendar, } from 'react-icons/bi';
import { ClipLoader } from "react-spinners";
import { Calendar as MUICalendar } from '@material-ui/pickers'

import {
  Button,
  Dialog,
} from '../../components';
import { getWeeklyPlan, updateWeeklyPlanList } from '../../store/app';
import { FuncService } from '../../services/funcService';

function formatDate(date, short = true) {
  if (short) {
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${day} ${month}`;
  } else {
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${day} ${month}`;
  }
}

function getSunday(date) {
  date = new Date(date);
  var day = date.getDay(),
    diff = date.getDate() - day; // adjust when day is sunday

  return new Date(date.setDate(diff));
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);

  return date;
}

function differenceInDays(d0, d1) {
  // Copy dates so don't affect originals
  d0 = new Date(+d0);
  d1 = new Date(+d1);

  // Set to noon
  d0.setHours(12, 0, 0, 0);
  d1.setHours(12, 0, 0, 0);

  // Get difference in whole days, divide by milliseconds in one day
  // and round to remove any daylight saving boundary effects
  return Math.round((d1 - d0) / 8.64e7)
}

var days_short = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
var days_long = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thusday', 'Friday', 'Saturday'];


const Calendar = (props) => {

  const dispatch = useDispatch();

  const weeklyPlans = useSelector(state => state.app.weeklyPlans);
  const profile = useSelector(state => state.session.profile);

  const language = profile?.langId ?? 1;

  const { editable, onChangeMealTime, } = props;
  const [startDate, setStartDate] = useState(getSunday(new Date()));
  const [currentMeal, setCurrentMeal] = useState({});
  const [currentEdit, setCurrentEdit] = useState({});
  const [updatedWeeklyPlans, setUpdatedWeeklyPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    console.log(startDate);
    dispatch(getWeeklyPlan(FuncService.formatDate(startDate, true)));
  }, [startDate]);

  useEffect(() => {
    if (!weeklyPlans)
      return;

    // setUpdatedWeeklyPlans(weeklyPlans.slice());
    setUpdatedWeeklyPlans(JSON.parse(JSON.stringify(weeklyPlans)));
  }, [weeklyPlans]);

  const handlePrev = () => {
    setStartDate(startDate.addDays(-7));
  }

  const handleToday = () => {
    setStartDate(getSunday(new Date()));
  }

  const handleNext = () => {
    setStartDate(startDate.addDays(7));
  }

  const handleEdit = (date, mealTime) => {
    setCurrentEdit({
      date: date,
      mealTime: mealTime,
    });

    setOpen(true);
  }

  const handleClick = (dateIndex, timeIndex) => {
    console.log(dateIndex, timeIndex);
    setCurrentMeal({
      date: startDate.addDays(dateIndex),
      time: timeIndex,
    });

    if (onChangeMealTime)
      onChangeMealTime(FuncService.formatDate(startDate.addDays(dateIndex), true), timeIndex + 1);
  }

  const handleSave = () => {
    dispatch(updateWeeklyPlanList(
      currentEdit.date,
      currentEdit.mealTime,
      updatedWeeklyPlans.find(weeklyPlan => weeklyPlan.date === currentEdit.date && weeklyPlan.mealTime === currentEdit.mealTime).meals
    ));

    setOpen(false);
  }

  const handleClose = () => {
    setUpdatedWeeklyPlans(JSON.parse(JSON.stringify(weeklyPlans)));

    setOpen(false);
  }

  const handleChangeAmount = (id, amount) => {
    let tmpWeeklyPlans = updatedWeeklyPlans.slice();
    let tmpWeeklyPlan = tmpWeeklyPlans.find(weeklyPlan => weeklyPlan.date === currentEdit.date && weeklyPlan.mealTime === currentEdit.mealTime);
    tmpWeeklyPlan.meals.find(meal => meal.id === id).amount = amount ? parseInt(amount) : '';

    setUpdatedWeeklyPlans(tmpWeeklyPlans);
  }

  const handleDelete = (id) => {
    let tmpWeeklyPlans = updatedWeeklyPlans.slice();
    let tmpWeeklyPlan = tmpWeeklyPlans.find(weeklyPlan => weeklyPlan.date === currentEdit.date && weeklyPlan.mealTime === currentEdit.mealTime);
    const index = tmpWeeklyPlan.meals.indexOf(tmpWeeklyPlan.meals.find(meal => meal.id === id));
    tmpWeeklyPlan.meals.splice(index, 1);

    setUpdatedWeeklyPlans(tmpWeeklyPlans);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setStartDate(getSunday(date));
    setCalendarOpen(false);
  };

  const handleCalendar = () => {
    setCalendarOpen(true);
  }

  const findMatchingMeal = (meals, startDate, dateIndex, timeIndex) => {
    console.log(meals, differenceInDays(meals.date, startDate.addDays(dateIndex)) === 0, meals.mealTime, timeIndex);
    return differenceInDays(meals.date, startDate.addDays(dateIndex)) === 0 && meals.mealTime == timeIndex;
  }


  return (
    <Fragment>
      <div>
        {
          !weeklyPlans ? (
            <div className="HV-center h-100">
              <ClipLoader color='#FC9C52' size={35} />
            </div>
          ) : (
            <div className="col">
              <div className="row justify-content-between">
                <div style={{ width: '9%', paddingTop: 120, }}>
                  <p style={{ height: 120, }}>Lunch</p>
                  <p>Dinner</p>
                </div>
                {
                  Array.from(Array(7).keys()).map((date, dateIndex) => (
                    <div key={dateIndex} style={{ width: '13%', flex: 1, minWidth: 100, borderLeft: '1px solid #EBECEE', }}>
                      <div className="calendar-day text-left">
                        <span className="calendar-day-text">
                          {days_short[startDate.addDays(dateIndex).getDay()]}
                        </span>
                        <br></br>
                        <span className="calendar-date-text">
                          {formatDate(startDate.addDays(dateIndex))}
                        </span>
                      </div>
                      {
                        Array.from(Array(2).keys()).map((mealTime, timeIndex) => {
                          const plannedMeals = weeklyPlans.find(weeklyPlan => weeklyPlan.date === FuncService.formatDate(startDate.addDays(dateIndex), true) && weeklyPlan.mealTime === timeIndex + 1);
                          return (
                            <div
                              key={timeIndex}
                              className="calendar-meal-time pl-2"
                              style={{
                                backgroundImage: `url(${plannedMeals ? plannedMeals.meals?.[0]?.image : null})`,
                                backgroundSize: '100% 100%',
                                backgroundPosition: 'center center',
                                backgroundRepeat: 'no-repeat',
                                ...(plannedMeals ? (
                                  timeIndex === 0 ?
                                    { backgroundColor: '#F5F8FE', borderLeft: '3px solid #2F80ED' }
                                    : { backgroundColor: '#FFF0E5', borderLeft: '3px solid #FC9C52' }
                                ) : differenceInDays(currentMeal.date, startDate.addDays(dateIndex)) === 0 && currentMeal.time === timeIndex ? { backgroundColor: '#e5e5e5' } : null)
                              }}
                              onClick={() => onChangeMealTime ? handleClick(dateIndex, timeIndex) : {}}
                            >
                              {
                                plannedMeals && editable ? (
                                  <span className="edit pointer" onClick={() => handleEdit(FuncService.formatDate(startDate.addDays(dateIndex), true), timeIndex + 1)}>
                                    <BsPencil style={{ color: timeIndex === 0 ? '#2F80ED' : '#FC9C52', fontSize: 14, }} />
                                  </span>
                                ) : null
                              }
                              {
                                plannedMeals ? (
                                  <div style={{ paddingTop: 25, }}>
                                    {
                                      plannedMeals.meals.map((meal, mealIndex) => (
                                        <p style={{ fontSize: 14, fontWeight: 600, textAlign: 'left', textShadow: '1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white'}} key={mealIndex}>
                                          {meal.title[language]}
                                        </p>
                                      ))
                                    }
                                  </div>
                                ) : null
                              }
                              {
                                plannedMeals ? (
                                  plannedMeals.meals.map((meal, mealIndex) => (
                                    <span className="calorie" key={mealIndex} style={{textShadow: '1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white'}}>
                                      {Math.round(meal.calories)} Calories
                                    </span>
                                  ))
                                ) : null
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  ))
                }
              </div>
              <div className="row justify-content-between mt-3" style={{ paddingLeft: '9%', }}>
                <div>
                  <span className="calendar-today-date-text">
                    {formatDate(selectedDate, true)}
                  </span>
                  <br></br>
                  <span className="calendar-today-day-text">
                    {days_long[selectedDate.getDay()]}
                  </span>
                </div>
                <div>
                  <Button
                    variant=""
                    bgColor="primary"
                    width={40}
                    height={40}
                    style={{ padding: 0, marginLeft: 5, }}
                    onClick={handlePrev}
                  >
                    <AiOutlineLeft style={{ fontSize: 14, color: '#3490DD' }} />
                  </Button>
                  <Button
                    variant="outlined"
                    bgColor="primary"
                    width={156}
                    height={40}
                    style={{ borderRadius: 6, padding: 0, marginRight: 10, }}
                    onClick={handleToday}
                  >
                    Jump To Today
                  </Button>
                  <Button
                    variant="contained"
                    bgColor="primary"
                    width={68}
                    height={40}
                    style={{ borderRadius: 6, padding: 0, margin: '0 1px 0 1px' }}
                    onClick={handleCalendar}
                  >
                    <BiCalendar style={{ fontSize: 20, color: 'white', marginRight: 10, }} />
                    <BsChevronDown style={{ fontSize: 14, color: 'white' }} />
                  </Button>
                  <Button
                    variant=""
                    bgColor="secondary"
                    width={40}
                    height={40}
                    style={{ padding: 0 }}
                    onClick={handleNext}
                  >
                    <AiOutlineRight style={{ fontSize: 14, color: '#3490DD', }} />
                  </Button>
                </div>
              </div>
            </div>
          )
        }
      </div>

      <Dialog
        open={calendarOpen}
        fullWidth={false}
        onClose={() => setCalendarOpen(false)}
      >
        <MUICalendar
          date={selectedDate}
          onChange={(date) => handleDateChange(date)}
        />

      </Dialog>

      <Dialog
        open={open}
        fullWidth={false}
        onClose={() => setOpen(false)}
      >
        <div style={{ marginTop: 30, }}>
          {
            updatedWeeklyPlans.filter(weeklyPlan => weeklyPlan.date === currentEdit.date && weeklyPlan.mealTime === currentEdit.mealTime)
              .map(weeklyPlan => (
                weeklyPlan.meals.map((meal, mealIndex) => (
                  <div key={mealIndex} className="d-flex justify-content-between align-items-center mt-2">
                    <span>
                      {meal.title[language]}
                    </span>
                    <input type="text" style={{ width: 30, textAlign: 'center', }} value={meal.amount} onChange={(val) => handleChangeAmount(meal.id, val.target.value)} />
                    <BiTrash className="pointer" style={{ fontSize: 20, color: 'red', }} onClick={() => handleDelete(meal.id)} />
                  </div>
                ))
              ))
          }
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, marginRight: 10, }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, }}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </Fragment>
  );
}

export default Calendar;