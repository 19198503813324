import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import BeatLoader from "react-spinners/BeatLoader";

import { Button, Calendar } from '../../../components';
import { getGroceryTypes, getGroceries, getRecipe } from '../../../store/app/actions';
import { history } from '../../../store/history';


const Groceries = (props) => {

  const meals = props.location.state?.meals;
  const from = props.location.state?.from;

  const dispatch = useDispatch();

  const groceries = useSelector(state => state.app.groceries);
  const groceryTypes = useSelector(state => state.app.groceryTypes);

  const profile = useSelector(state => state.session.profile);

  useEffect(() => {
    if (!meals || !meals.length)
      return;

    dispatch(getGroceries(meals));
    dispatch(getGroceryTypes());
  }, [meals]);

  const language = profile?.langId ?? 1;


  if (meals && meals.length == 0) {
    return (
      <div className="HV-center h-100">
        No Groceries.
      </div>
    );
  }

  if (!groceries || !groceryTypes) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color='#FC9C52' size={20} margin={5} />
      </div>
    );
  }


  return (
    <React.Fragment>
      <div className="d-flex flex-column text-center" style={{ padding: '20px 10%', }}>
        <Button
          variant="contained"
          bgColor="primary"
          fontColor="white"
          style={{ height: 30, width: 30, borderRadius: 15, padding: 0, }}
          onClick={() => history.push(from ?? '/home')}>
          <BsArrowLeft style={{ fontSize: 24, height: 20, color: 'white', paddingRight: 5, }} />
        </Button>
        <p style={{ fontSize: 30, fontWeight: 'bold', textAlign: 'center', }}>
          Grocery Shopping List
        </p>
        {
          groceries.map((grocery, index) => (
            <div key={index}>
              <p className="font-weight-bold">
                {`${index + 1}. ${groceryTypes ? Object.values(groceryTypes).find(g => g.id == grocery.market)?.description ?? '???' : ''}`}
              </p>
              {
                grocery.groceries.map((groceryItem, itemIndex) => (
                  <div key={itemIndex} style={{display: "flex"}}>
                    {
                      groceryItem.image ? 
                      <img src={groceryItem.image} width="24" height="24" style={{flexBases: "100px"}}></img>
                      : null
                    }
                    <p key={itemIndex} className="ml-1" style={{flexBases: "100px"}}>
                      {groceryItem.description} - {groceryItem.quantity}g
                    </p>
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    </React.Fragment>
  )
}


export default Groceries;
