import React, { useState } from "react";
import { Auth } from 'aws-amplify';

import {
  Button,
  LineEdit,
} from "../../../components";
import { history } from '../../../store/history';


const ConfirmUser = (props) => {

  const [code, setCode] = useState('');

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      const email = props.location.state.email;
      await Auth.confirmSignUp(email, code);
      history.push('/sign-in');
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Confirm User</h1>
        <p className="lead text-center">Enter code to confirm your user name.</p>
      </div>
      <div style={{ maxWidth: 400, margin: 'auto', textAlign: 'center', }}>
        <form className="m-sm-4" onSubmit={handleConfirm}>
          <LineEdit
            type="text"
            name="code"
            placeholder="Enter code"
            value={code}
            onChange={val => setCode(val)}
          />
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={120}
            height={36}
            style={{ margin: 'auto', marginTop: 30, }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </form>
      </div>
    </React.Fragment >
  );
}


export default ConfirmUser;
