import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { FiShoppingCart } from 'react-icons/fi';
import { BsArrowLeft } from 'react-icons/bs';

import {
  Pagination,
  RecipeItem,
  Button,
} from '../../../components';
import { getPreferenceList, clearPrefer } from '../../../store/app/actions';
import { history } from '../../../store/history';

import { ApiService } from '../../../services/apiService';
import { showToastr } from '../../../services/themeService';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;


const List = () => {

  const dispatch = useDispatch();

  const preferences = useSelector(state => state.app.preferences);

  const [page, setPage] = useState(1);
  const [meals, setMeals] = useState(null);

  useEffect(() => {
    if (!preferences)
      handlePageChange(1);
  }, []);

  useEffect(() => {
    if (!preferences)
      return;

    let meals = [];
    if (preferences.meals !== undefined) {
      preferences.meals.map(meal => {
        meals.push({
          id: meal.id,
          amount: 1,
        })
      });
    }
    setMeals(meals);
  }, [preferences]);

  const handlePageChange = (val) => {
    setPage(val);

    dispatch(getPreferenceList(val, 12, 'id', 'asc'));
  }

  const handleClear = () => {
    ApiService.deleteMyList()
      .then(data => {
        console.log("data:", data);
        showToastr("info", "Success", "Successfully deleted list");
        setMeals({});
        dispatch(clearPrefer());
      })
      .catch(e => {
        console.log("Error deleting list:", e);
        showToastr("error", "Error", "An error occurred while deleting the list");
      })
  }

  const handleGroceries = () => {
    history.push('/groceries', { meals: meals, from: '/preferences/list' });
  }


  return (
    <div style={{ padding: '0 10%', }}>
      <div className="d-flex justify-content-between align-items-center mb-5" style={{ width: '100%', marginTop: 50, }}>
        <div>
          <p style={{ fontSize: 38, fontWeight: 'bold', }}>
            My List
          </p>
          <div style={{ width: 60, borderBottom: '2px solid #FC9C52', }}></div>
        </div>
        <div>
          {
            (preferences?.meals?.length > 0) ? (
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                className="mr-2"
                width={110}
                height={38}
                onClick={handleClear}>
                Clear List
              </Button>
            ) : null
          }
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={175}
            height={38}
            onClick={handleGroceries}
          >
            <FiShoppingCart style={{ fontSize: 16, color: 'white', marginRight: 10, }} />
            Show Groceries
          </Button>
        </div>
      </div>
      <div className="mb-4">
        <Link to="/home" className="mr-2" style={{ color: '#6B6C72', textDecoration: 'none', fontWeight: 600 }}>
          <BsArrowLeft style={{ fontSize: 18, marginRight: 5, }} />Home
        </Link>
        <Link to="/preferences/list" style={{ color: '#6B6C72', textDecoration: 'none', fontWeight: 600 }}>Weekly Plan</Link>
      </div>
      {
        !preferences ? (
          <div className="">
            <GridLoader color='#FC9C52' loading={true} css={override} size={15} margin={2} />
          </div>
        ) : (
          <React.Fragment>
            <div className="row m-0">
              {
                (preferences?.meals?.length > 0) ? 
                preferences.meals.map((recipe, index) => (
                  <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                    <RecipeItem
                      recipe={recipe}
                    />
                  </div>
                )) : (
                <div>
                  <br></br>
                  <span className="text-center" style={{ fontSize: 18, fontWeight: 400, color: '#6B6C72' }}>
                    Your list is empty
                  </span>
                  <br></br>
                </div>
                )
              }
            </div>
            <Pagination
              value={page ?? 1}
              maxPage={preferences.pages}
              onUpdate={(val) => handlePageChange(val)}
              onChange={setPage}
            />
          </React.Fragment>
        )
      }
    </div>
  );
};

export default List;
