import React from 'react';

import {
  FaFacebook,
  FaGoogle,
  FaApple,
} from 'react-icons/fa';


const SocialSignButton = (props) => {
  const { google, facebook, apple, style, bgColor, children, onClick } = props;

  return (
    <a className="social-sign-button pointer" style={{ color: 'white', backgroundColor: bgColor, fontSize: 16, fontWeight: 600 }} onClick={onClick}>
      {google ? <FaGoogle size={20} /> : null}
      {facebook ? <FaFacebook size={20} /> : null}
      {apple ? <FaApple size={20} /> : null}
      <span style={{ width: 8 }}></span>
      {children}
    </a>
  );
}

export default SocialSignButton;