import React, { useState } from 'react';

import {
  HiOutlineUser,
  HiOutlineMail,
  HiOutlineLockClosed,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi';


const LineEdit = (props) => {

  const { placeholder, type, name, style, value, readOnly, onChange } = props;


  return (
    <div className="sign-edit-container" style={style}>
      <input
        type={type}
        name={name}
        className="custom-input"
        placeholder={placeholder}
        readOnly={readOnly}
        value={value}
        onChange={(val) => onChange(val.target.value)}
      />
    </div>
  );
}

export default LineEdit;