export const awsConfig = {
    Auth: {
        identityPoolId: 'eu-west-3:174814d7-261d-4ae9-ac2e-70fb36200f33',
        region: 'eu-west-3',
        userPoolId: 'eu-west-3_TKYlBCCGr',
        userPoolWebClientId: '40t91l1ogrtc1u723aud38dv1n',
        oauth: {
            domain: 'myperfectmeal.auth.eu-west-3.amazoncognito.com',
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: 'http://localhost:3000/home,https://user.myperfectmeal.org/home',
            redirectSignOut: 'http://localhost:3000/sign-in,https://user.myperfectmeal.org/sign-in',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
    API: {
        endpoints: [
            {
                name: 'dev-calculation-type-api',
                endpoint: 'https://quv6slmvog.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-country-api',
                endpoint: 'https://m0gf5mugr3.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-cuisine-api',
                endpoint: 'https://v2zi9kncd6.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-difficulty-level-api',
                endpoint: 'https://jw40peynvj.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-field-api',
                endpoint: 'https://g3b98q98e8.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-global-params-api',
                endpoint: 'https://i6p3nd3zrj.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-group-api',
                endpoint: 'https://du5rci2tf0.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-ingredient-api',
                endpoint: 'https://oxaph9bqwk.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-language-api',
                endpoint: 'https://vl7hm8qsqg.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-meal-api',
                endpoint: 'https://onqc3qp2jl.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-meal-level-api',
                endpoint: 'https://0i8ouqe0tk.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-mealquality-type-api',
                endpoint: 'https://xkuqvthxsf.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-rdd-api',
                endpoint: 'https://kw77fn45hl.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-status-api',
                endpoint: 'https://86nu1nap7h.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-tab-api',
                endpoint: 'https://h14yke8zb6.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-user-api',
                endpoint: 'https://qs7rv00vzg.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-vegetariantype-api',
                endpoint: 'https://eqflcoxo2m.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-weekly-api',
                endpoint: 'https://jr2ormtkl4.execute-api.eu-west-3.amazonaws.com/dev',
            },
            {
                name: 'dev-groceries-api',
                endpoint: 'https://ggy72dq1p7.execute-api.eu-west-3.amazonaws.com/dev',
            },
        ]
    }
}
