import React from 'react';
import Rating from 'react-rating';
import { FaStar } from 'react-icons/fa';
import { FiStar } from 'react-icons/fi';


const CarouselItem4 = (props) => {
  const { avatar, name, job, description, rating, style, children } = props;

  return (
    <div className="d-flex flex-column align-items-center" style={{ padding: '0 20px' }}>
      <span style={{ maxWidth: 300, fontSize: 18, fontWeight: 300, fontStyle: 'italic', fontFamily: 'Merriweather', color: '#07122E', marginBottom: 30, textAlign: 'center' }}>{description}</span>
      <img src={avatar} style={{ borderRadius: '50%', width: 50, marginBottom: 20 }}></img>
      <Rating
        emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
        fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
        initialRating={rating}
      />
      <span style={{ fontSize: 18, fontWeight: 700, color: '#07122E', marginBottom: 3, marginTop: 20 }}>{name}</span>
      <span style={{ fontSize: 16, fontWeight: 600, color: '#3490DD' }}>{job}</span>
    </div>
  );
}

export default CarouselItem4;