import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Rating from 'react-rating';
import { FaStar } from 'react-icons/fa';
import { FiStar } from 'react-icons/fi';
import { BsArrowLeft } from 'react-icons/bs';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";

import { Button } from '../../../components';
import { getRecipe } from '../../../store/app/actions';
import { history } from '../../../store/history';

import PrepImage from '../../../assets/image/prep.png';
import CookImage from '../../../assets/image/cook.png';

import FoodImage1 from '../../../assets/image/tmp/food-1.png';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;


const Votes = (props) => {

  const location = useLocation();
  const routes = location.pathname.split('/');
  const id = parseInt(routes[routes.length - 2]);

  const dispatch = useDispatch();

  const preferences = useSelector(state => state.app.preferences);
  const recipeInfo = useSelector(state => state.app.recipeInfo);
  const inRecipe = useSelector(state => state.app.recipe);
  const profile = useSelector(state => state.session.profile);

  useEffect(() => {
    if (!recipeInfo || (recipeInfo && recipeInfo.meals.find(recipe => recipe.id == id) === undefined) && (preferences && preferences.meals.find(recipe => recipe.id == id) === undefined)) {
      dispatch(getRecipe(id));
    }
  }, []);

  let meal;
  if (recipeInfo)
    meal = recipeInfo.meals.find(recipe => recipe.id == id);
  if (!meal && preferences)
    meal = preferences.meals.find(recipe => recipe.id == id);
  if (!meal)
    meal = inRecipe;

  const recipe = meal;

  const language = profile?.langId ?? 1;


  if (!recipe) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color='#FC9C52' size={20} margin={5} />
      </div>
    );
  }


  return (
    <React.Fragment>
      <Button
        variant="contained"
        bgColor="primary"
        fontColor="white"
        width={30}
        height={30}
        style={{ borderRadius: 15, padding: 0, }}
        onClick={() => history.push(`/vegetarian-food/detail/${id}`)}
      >
        <BsArrowLeft style={{ fontSize: 24, height: 20, color: 'white', paddingRight: 5, }} />
      </Button>
      <div style={{ padding: '20px 10%', }}>
        <div>
          <div className="d-flex align-items-center mb-5">
            <img
              src={recipe.image ?? FoodImage1}
              className="rounded"
              alt="Placeholder"
            />
            <div className="ml-5">
              <p style={{ fontSize: 24, fontWeight: 600, color: 'black', marginBottom: 10, }}>
                {recipe.title[language]}
              </p>
              <p style={{ color: '#3490DD', }}>
                {`Submitted by ${recipe.author ?? ''}`}
              </p>
              <span className="d-flex align-items-center mb-2">
                <img
                  src={PrepImage}
                  className="mr-2"
                />
                {`${recipe.preparationTime} mins to prep`}
                <img
                  src={CookImage}
                  className="ml-4 mr-2"
                />
                {`${recipe.cookingTime} mins to cook`}
              </span>
              <p>
                {`Serves: ${recipe.serves}`}
              </p>
            </div>
          </div>
        </div>
        <div>
          {
            recipe.votes.comments.map((vote, index) => (
              <div style={{ borderRadius: 6, border: '0.5px solid #E8EDF5', padding: '5px 5%', margin: 5, }} key={index}>
                <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 5, }}>
                  {vote.userName}
                </p>
                <Rating
                  emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                  fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                  initialRating={vote.star}
                  readonly
                />
                <p style={{ marginTop: 10, }}>
                  {vote.comment}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </React.Fragment>
  )
}


export default Votes;
