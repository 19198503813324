import * as types from './actionTypes'

const initialState = {
  loginLoading: false,
  logoutLoading: false,
  passwordChanging: false,
  passwordChangeResult: null,
  registerLoading: false,
  isAuthenticated: false,
  user: null,
  profileLoading: false,
  profile: null,
  filterOptions: {
    keyword: '',
    cuisines: [],
    vegetarianTypes: [],
    difficultyLevels: [],
    evaluationStars: [],
    preparationTime: {
      range: {
        min: 0,
        max: 120,
      },
      value: {
        min: 0,
        max: 120,
      },
    },
    serves: {
      range: {
        min: 0,
        max: 8,
      },
      value: {
        min: 0,
        max: 8,
      },
    },
    userStars: {
      range: {
        min: 0,
        max: 5,
      },
      value: {
        min: 0,
        max: 5,
      },
    },
    energy: {
      range: {
        min: 0,
        max: 800,
      },
      value: {
        min: 0,
        max: 800,
      },
    },
    protein: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    fat: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    sugar: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    carbohydrate: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    fiber: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
  },
  error: '',
}

const session = (state = initialState, action) => {
  switch (action.type) {

    //LOGIN REDUCER
    case types.LOGIN_LOADING:
      return { ...state, loginLoading: true, error: null }
    case types.LOGIN_SUCCESS:
      return { ...state, loginLoading: false, isAuthenticated: true, user: action.user, error: null }
    case types.LOGIN_ERROR:
      return { ...state, loginLoading: false, user: null, isAuthenticated: false, error: action.error }

    //PASSWORD CHANGE REDUCER
    case types.PASSWORD_CHANGING:
      return { ...state, passwordChanging: true, passwordChangeResult: null}
    case types.PASSWORD_CHANGE_SUCCESS:
      return { ...state, passwordChanging: false, passwordChangeResult: true}
    case types.PASSWORD_CHANGE_ERROR:
      return { ...state, passwordChanging: false, passwordChangeResult: false}
      case types.PASSWORD_CHANGE_END:
    return { ...state, passwordChanging: false, passwordChangeResult: null}

    //LOGOUT REDUCER
    case types.LOGOUT_LOADING:
      return { ...state, logoutLoading: true, error: null }
    case types.LOGOUT_SUCCESS:
      return initialState
    case types.LOGOUT_ERROR:
      return { ...state, logoutLoading: false, error: action.error }

    //REGISTER REDUCER
    case types.REGISTER_LOADING:
      return { ...state, registerLoading: true, error: null }
    case types.REGISTER_SUCCESS:
      return { ...state, registerLoading: false, error: null }
    case types.REGISTER_ERROR:
      return { ...state, registerLoading: false, error: action.error }

    //PROFILE REDUCER
    case types.PROFILE_LOADING:
      return { ...state, profileLoading: true, error: null }
    case types.PROFILE_SUCCESS:
      return { ...state, profileLoading: false, profile: action.profile, error: null }
    case types.PROFILE_ERROR:
      return { ...state, profileLoading: false, profile: null, error: action.error }

    //FILTER REDUCER
    case types.FILTER_SUCCESS:
      return { ...state, filterOptions: action.filter, error: null }

    default:
      return state
  }
}

export default session
