import { config } from '../config';
import { API, Auth } from 'aws-amplify';
import store from '../store';

// function login(email, pass) {
//   const options = {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest'
//     },
//     body: JSON.stringify({
//       type: 'admin',
//       name: email,
//       password: pass,
//     })
//   };

//   return fetch(config.API_URL + 'auth/login', options);
// }

// function logout(session) {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//       'Authorization': session,
//     },
//   };

//   return fetch(config.API_URL + 'auth/logout', options);
// }

// function updateAdmin(session, profile) {
//   const data = new FormData();

//   console.log(profile.image);
//   console.log(typeof profile.image);
//   if (typeof profile.image != 'string' && profile.image)
//     data.append('file', profile.image);
//   data.append('id', profile.id);
//   data.append('name', profile.name);
//   data.append('email', profile.email);

//   const options = {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Authorization': session,
//     },
//     body: data
//   };

//   return fetch(config.API_URL + 'admin/update', options);
// }

// function forgotPass(email) {
//   const options = {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//     body: JSON.stringify({
//       email: email,
//     }),
//   };

//   return fetch(config.API_URL + 'pass/forgot', options);
// }

// function findToken(token) {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//   };

//   return fetch(config.API_URL + 'pass/find/' + token, options);
// }

// function resetPass(email, pass, confirmPass, token) {
//   const options = {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//     body: JSON.stringify({
//       email: email,
//       password: pass,
//       password_confirmation: confirmPass,
//       token: token
//     }),
//   };

//   return fetch(config.API_URL + 'pass/reset', options);
// }

// function confirmEmail(token) {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//   };

//   return fetch(config.API_URL + 'auth/confirm/' + token, options);
// }

function getLanguage() {
  const languages = store.getState().app.languages;
  const language = store.getState().app.language;
  if (!languages)
    return 1;
  if (!language)
    return Object.keys(languages)?.find?.(l => languages[l].code.toLowerCase() == (window.navigator.language || window.navigator.userLanguage)) ?? 1;
  return Object.keys(languages)?.find?.(l => languages[l].code == language.value) ?? 1;
}

async function getLanguages() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'languages/list', options);
    return response.json();
  } else {
    const apiName = 'dev-language-api';
    const path = '/languages/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getMealLevels() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + `/meal-levels/list?language=${getLanguage()}`, options);
    return response.json();
  } else {
    const apiName = 'dev-meal-level-api';
    const path = `/meal-levels/list?language=${getLanguage()}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
    };
    return API.get(apiName, path, init);
  }
}

async function getFields() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'fields/list', options);
    return response.json();
  } else {
    const apiName = 'dev-field-api';
    const path = '/fields/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getIngredients(page, count, sortField, sortBy, keyword) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: keyword,
      })
    };

    const response = await fetch(config.API_URL + 'ingredients/list', options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = '/ingredients/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: keyword,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function getIngredient(code) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'ingredients/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = `/ingredients/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getRDDs(page, count, sortField, sortBy, filter) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      })
    };

    const response = await fetch(config.API_URL + 'rdds/list', options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = '/rdds/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function getRDD(code, type) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        calcTypeId: type,
      }),
    };

    const response = await fetch(config.API_URL + 'rdds/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = `/rdds/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        calcTypeId: type
      }
    };

    return API.post(apiName, path, init);
  }
}


async function getRecipes(page, count, sortField, sortBy, filter) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        ...filter,
      })
    };

    const response = await fetch(config.API_URL + 'meals/list', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = '/meals/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        ...filter,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function getRecipe(code) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'meals/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getRecommendations() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'meals/daily', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = '/meals/daily';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getCalcTypes() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'calculation-types/list', options);
    return response.json();
  } else {
    const apiName = 'dev-calculation-type-api';
    const path = '/calculation-types/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getDifficultyLevels() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + `/difficulty-levels/list?language=${getLanguage()}`, options);
    return response.json();
  } else {
    const apiName = 'dev-difficulty-level-api';
    const path = `/difficulty-levels/list?language=${getLanguage()}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getCuisines() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      }
    };

    const response = await fetch(config.API_URL + `/cuisines/list?language=${getLanguage()}`, options);
    return response.json();
  } else {
    const apiName = 'dev-cuisine-api';
    const path = `/cuisines/list?language=${getLanguage()}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getVegetarianTypes() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      }
    };

    const response = await fetch(config.API_URL + `/vegetarian-types/list?language=${getLanguage()}`, options);
    return response.json();
  } else {
    const apiName = 'dev-vegetariantype-api';
    const path = `/vegetarian-types/list?language=${getLanguage()}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getCountries() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'countries/list', options);
    return response.json();
  } else {
    const apiName = 'dev-country-api';
    const path = '/countries/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getPrefers(page, count, sortField, sortBy) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
      })
    };

    const response = await fetch(config.API_URL + 'meals/prefers', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/prefers`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function deleteMyList() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      }
    };

    const response = await fetch(config.API_URL + 'meals/prefers', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = '/meals/prefers';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.del(apiName, path, init);
  }
}

async function deleteMyWeeklyPlan() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      }
    };

    const response = await fetch(config.API_URL + 'weeklyPlan', options);
    return response.json();
  } else {
    const apiName = 'dev-weekly-api';
    const path = '/weeklyPlan';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.del(apiName, path, init);
  }
}

async function getProfile(id) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
    };

    const response = await fetch(config.API_URL + 'users/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-user-api';
    const path = `/users/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function updateProfile(id, profile) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify(profile)
    };

    const response = await fetch(config.API_URL + 'users/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-user-api';
    const path = `/users/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: profile
    };

    return API.put(apiName, path, init);
  }
}

async function updateVoting(recipe_id, star, comment, token) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        star: star,
        comment: comment,
      })
    };

    const response = await fetch(config.API_URL + 'meals/' + recipe_id + '/vote', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${recipe_id}/vote`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        star: star,
        comment: comment,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function updatePrefer(recipe_id, prefer, token) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        preferred: prefer,
      })
    };

    const response = await fetch(config.API_URL + 'meals/' + recipe_id + '/prefer', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${recipe_id}/prefer`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        preferred: prefer,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function addWeeklyPlan(id, date, mealTime, amount) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        date: date,
        mealTime: mealTime,
        amount: amount
      })
    };

    const response = await fetch(config.API_URL + 'weeklyPlan/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-weekly-api';
    const path = `/weeklyPlan/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
        amount: amount
      }
    };

    return API.post(apiName, path, init);
  }
}

async function getWeeklyPlan(date) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        sunday: date,
      })
    };

    const response = await fetch(config.API_URL + 'weeklyPlan/list', options);
    return response.json();
  } else {
    const apiName = 'dev-weekly-api';
    const path = '/weeklyPlan/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        sunday: date,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function deleteWeeklyPlan(id, date, mealTime) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        startDate: date,
        mealTime: mealTime,
      })
    };

    const response = await fetch(config.API_URL + 'weeklyPlan/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-weekly-api';
    const path = '/weeklyPlan/' + id;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
      }
    };

    return API.del(apiName, path, init);
  }
}

async function updateWeeklyPlan(id, date, mealTime, amount) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        startDate: date,
        mealTime: mealTime,
        amount: amount,
      })
    };

    const response = await fetch(config.API_URL + 'weeklyPlan/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-weekly-api';
    const path = '/weeklyPlan/' + id;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
        amount: amount,
      }
    };

    return API.put(apiName, path, init);
  }
}

async function updateWeeklyPlanList(date, mealTime, meals) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify({
        startDate: date,
        mealTime: mealTime,
        meals: meals,
      })
    };

    const response = await fetch(config.API_URL + 'weeklyPlan/updateList', options);
    return response.json();
  } else {
    const apiName = 'dev-weekly-api';
    const path = '/weeklyPlan/updateList';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
        meals: meals,
      }
    };

    return API.put(apiName, path, init);
  }
}

async function getGroceries(meals) {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      },
      body: JSON.stringify(meals)
    };

    const response = await fetch(config.API_URL + 'groceries/list', options);
    return response.json();
  } else {
    const apiName = 'dev-groceries-api';
    const path = '/groceries/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
      body: meals
    };

    return API.post(apiName, path, init);
  }
}

async function getGroceryTypes() {
	let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      }
    };

    const response = await fetch(config.API_URL + `groceries/types?language=${getLanguage()}`, options);
    return response.json();
  } else {
    const apiName = 'dev-groceries-api';
    const path = `/groceries/types?language=${getLanguage()}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      }
    };

    return API.get(apiName, path, init);
  }
}

export const ApiService = {
  getLanguages,
  getFields,
  getIngredient,
  getIngredients,
  getRDD,
  getRDDs,
  getRecipe,
  getRecipes,
  getCalcTypes,
  getDifficultyLevels,
  getCuisines,
  getVegetarianTypes,
  getCountries,
  getPrefers,
  deleteMyList,
  deleteMyWeeklyPlan,
  getProfile,
  updateProfile,
  updateVoting,
  updatePrefer,
  addWeeklyPlan,
  getWeeklyPlan,
  updateWeeklyPlan,
  updateWeeklyPlanList,
  deleteWeeklyPlan,
  getGroceries,
  getGroceryTypes,
  getMealLevels,
  getRecommendations,
}
