import React from 'react';
import { useSelector } from 'react-redux';


const IngredientElementItem = (props) => {

  const { ingredient, } = props;

  const profile = useSelector(state => state.session.profile);

  const language = useSelector(state => state.app.language);
  const languages = useSelector(state => state.app.languages);

  const languageId = (language) => {
    if (language === null)
      return 1;
    const index = Object.keys(languages).find(key => languages[key]["code"] == language["value"]) ?? 1;
    return index;
  }


  return (
    <div className="ingredient-element-item">
      <img
        src={ingredient.image}
        className="rounded mr-2"
        alt=""
        width={50}
        height={50}
      />
      <div className="mr-6 text-left">
        <p style={{ fontSize: 16, fontWeight: 600, color: 'black', marginBottom: 5, }}>
          {ingredient.friendlyDescription?.[languageId(language)] || ingredient.friendlyDescription[1]}
        </p>
      </div>
      <div className="ml-md-auto mr-5">
        <p style={{ color: '#07122E', marginBottom: 5, }}>
          {ingredient.descriptionBase?.[languageId(language)] || ingredient.descriptionBase?.[1]}
        </p>
        <p className="m-0">
          {`${ingredient.quantity} grams`}
        </p>
      </div>

    </div>
  )
}

export default IngredientElementItem;