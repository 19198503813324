import React, { useEffect, useState, } from 'react';
import { Link, } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  AiOutlineStar,
  AiFillStar,
} from 'react-icons/ai';
import Rating from 'react-rating';
import { FaStar, FaBookmark} from 'react-icons/fa';
import { FiStar, FiBookmark } from 'react-icons/fi';

import { updatePrefer, updateVoting, } from '../../store/app/actions';
import { useStoryblok } from '../../services/storyblokService';

import {
  TextArea,
  Button,
  Dialog,
} from '../../components';

import FoodImage1 from '../../assets/image/tmp/food-1.png';

const getNutritionalEvaluationString = (value) => {
  if (value < 3.5) {
    return "Adequate";
  }
  if (value < 4.5) {
    return "Superb";
  }
  return "Exceptional";
}

const RecipeItem = (props) => {

  const dispatch = useDispatch();

  const cuisines = useSelector(state => state.app.cuisines);
  const fields = useSelector((state) => state.app.fields);
  const difficultyLevels = useSelector((state) => state.app.difficultyLevels);
  const profile = useSelector(state => state.session.profile);
  // const preferLoading = useSelector((state) => state.app.)

  const { recipe } = props;
  const voteInfo = recipe.votes.comments.find(vote => vote.isOwn);

  const [preference, setPreference] = useState(recipe.preferred);
  const [rating, setRating] = useState(voteInfo?.star ?? 0);
  const [comment, setComment] = useState(voteInfo?.comment ?? '');
  const [open, setOpen] = useState(false);

  const language = profile?.langId ?? 1;
  const storyRecipeFields = useStoryblok("recipe-multilingue-fields", true);
  console.log("storyRecipeFields:", storyRecipeFields);

  useEffect(() => {
    setPreference(recipe.preferred);
  }, [recipe]);

  const handlePreference = () => {
    // setPreference(!preference);

    dispatch(updatePrefer(recipe.id, !preference));
  }

  const handleVoting = () => {
    setOpen(true);
  }

  const handleApply = () => {
    dispatch(updateVoting(recipe.id, rating, comment));

    setOpen(false);
  }

  const handleClose = () => {
    setRating(voteInfo?.star ?? 0);
    setComment(voteInfo?.comment ?? '')
    setOpen(false);
  }

  return (
    <React.Fragment>
      <div style={{ borderRadius: 6, textAlign: 'center', margin: '10px 0', border: '0.5px solid #F5F8FE' }}>
      <div style={{ backgroundImage: `url(${recipe.image ?? FoodImage1})`, width: '100%', paddingTop: '67.5%', backgroundSize: '100% 100%', borderRadius: 6, }}></div>
        {/* <img
          src={recipe.image ?? FoodImage1}
          alt="Placeholder"
          width="100%"
          style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6, }}
        /> */}
        {
          preference ?
            <FaBookmark className="pointer" style={{ fontSize: 22, position: 'absolute', top: 25, left: 30, color: 'white', }} onClick={handlePreference} />
            :
            <FiBookmark className="pointer" style={{ fontSize: 22, position: 'absolute', top: 25, left: 30, color: 'white', }} onClick={handlePreference} />
        }
        {
          voteInfo ?
            <AiFillStar className="pointer" style={{ fontSize: 22, position: 'absolute', top: 25, right: 30, color: 'white', }} onClick={handleVoting} />
            :
            <AiOutlineStar className="pointer" style={{ fontSize: 22, position: 'absolute', top: 25, right: 30, color: 'white', }} onClick={handleVoting} />
        }
        <div style={{ padding: '0 10px', marginTop: 5, }}>
          <p style={{ fontSize: 18, marginBottom: 5, color: 'black', fontWeight: 600, textAlign: 'center', }}>
            {recipe.title[language]}
          </p>
          <p style={{ fontSize: 16, marginBottom: 5, color: 'black', textAlign: 'center', }}>
            {cuisines[recipe.cuisineId].name}
          </p>
          <div className="d-flex justify-content-between text-center" style={{ color: 'black', }}>
            <div>
              <p style={{ color: 'black', fontSize: 14, fontWeight: 500, marginBottom: 0, }}>
                {storyRecipeFields?.content?.Nutrition}
              </p>
              <p className="text-center">
                {Object.keys(recipe.evaluations).length >= 1 ? getNutritionalEvaluationString(recipe.evaluations[0].value) : "???"}
              </p>
            </div>
            <div>
              <p style={{ color: 'black', fontSize: 14, fontWeight: 500, marginBottom: 0, }}>
                {storyRecipeFields?.content?.Difficulty}
              </p>
              <p className="text-center">
                {difficultyLevels[recipe.difficultyLevelId].name}
              </p>
            </div>
            <div>
              <p style={{ color: 'black', fontSize: 14, fontWeight: 500, marginBottom: 0, }}>
              {storyRecipeFields?.content?.Serves}
              </p>
              <p className="text-center">
                {recipe.serves}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="text-center">
              <p style={{ color: 'black', fontSize: 14, fontWeight: 500, marginBottom: 0, }}>
                {storyRecipeFields?.content?.Calorie}
              </p>
              <p className="text-center">
                {recipe.quantityPerPerson.find(quantity => quantity.fieldId == Object.entries(fields).find(field => field[1].display === 'Energy')[0]).value.toFixed(1)}
              </p>
            </div>
            <div className="text-center">
              <p style={{ color: 'black', fontSize: 14, fontWeight: 500, marginBottom: 0, }}>
                {storyRecipeFields?.content?.Protein}
              </p>
              <p className="text-center">
                {recipe.quantityPerPerson.find(quantity => quantity.fieldId == Object.entries(fields).find(field => field[1].display === 'Protein')[0]).value.toFixed(1)}
              </p>
            </div>
            <div className="text-center">
              <p style={{ color: 'black', fontSize: 14, fontWeight: 500, marginBottom: 0, }}>
                {storyRecipeFields?.content?.Fiber}
              </p>
              <p className="text-center">
                {recipe.quantityPerPerson.find(quantity => quantity.fieldId == Object.entries(fields).find(field => field[1].display === 'Fiber')[0]).value.toFixed(1)}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Link to={`/vegetarian-food/detail/${recipe.id}`}>
                Details
              </Link>
            </div>
            {
              recipe.ingredients.length > 0 ? (
                <span>
                  {/* {`${recipe.ingredients[0].MealRdd.starsNutritional}`} */}
                  {/* <Rating
                    emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                    fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                    stop={1}
                    initialRating={recipe.Rdds[0].MealRdd.starsNutritional / 5}
                    readonly
                  /> */}
                </span>
              ) : null
            }
            <span>
              {`(${Math.round(recipe.votes.voteStar * 10) / 10}`}
              <Link to={`/vegetarian-food/detail/${recipe.id}/votes`}>
                <Rating
                  emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                  fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                  stop={1}
                  initialRating={recipe.votes.voteStar / 5}
                  readonly
                />
              </Link>
              {`${recipe.votes.voteCount ?? '0'}+)`}
            </span>
          </div>
        </div>
      </div >

      <Dialog
        open={open}
        fullWidth={false}
        onClose={handleClose}
      >
        <div style={{ marginTop: 30, textAlign: 'center' }}>
          <p style={{ fontSize: 18, fontWeight: 600, }}>
            Please leave rating and comment.
          </p>
          <Rating
            emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
            fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
            initialRating={rating}
            onChange={val => setRating(val)}
            style={{ marginBottom: 20, }}
          />
          <br></br>
          <TextArea
            value={comment}
            onChange={val => setComment(val)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, marginRight: 10, }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            width={100}
            height={35}
            style={{ marginTop: 30, }}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  )
}

export default RecipeItem;