import React from 'react';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import {
  Button
} from '../../components';



const Pagination = (props) => {

  const { value, maxPage, onChange, onUpdate } = props;

  const handlePrev = () => {
    if (value === 1)
      return;

      onUpdate(value - 1);
  }

  const handleNext = () => {
    if (value === maxPage)
      return;

      onUpdate(value + 1);
  }

  return (
    <div className="d-flex justify-content-end align-items-center mt-4">
      {/* <Button color="info mr-4" style={{ width: 210, height: 50, color: '#3490DD', borderRadius: 100, }}>
        <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />
        Previous page
      </Button>
      <Button color="primary" style={{ width: 210, height: 50, marginRight: 30, borderRadius: 100, }}>
        Next page
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Button> */}
      {/* <div className="d-flex align-items-center" style={{ minWidth: 160, }}> */}
      Page
      <input
        style={{ outline: 'none', padding: '3px 0', margin: '0 10px', borderRadius: 6, width: 28, fontSize: 12, color: 'black', textAlign: 'center', border: '0.3px solid #E8EDF5' }}
        value={value}
        onChange={(val) => onChange(val.target.value)}
        onKeyUp={(e) => { if (e.key == "Enter") { onUpdate(e.target.value) } }}
      />
      of {maxPage}
      <Button
        variant="contained"
        bgColor="secondary"
        width={20}
        height={24}
        rect
        style={{ borderRadius: '6px 0 0 6px', padding: 0, marginLeft: 5, }}
        onClick={handlePrev}
      >
        <AiOutlineLeft style={{ fontSize: 14, color: 'white', }} />
      </Button>
      <Button
        variant="contained"
        bgColor="secondary"
        width={20}
        height={24}
        rect
        style={{ borderRadius: '0 6px 6px 0', padding: 0 }}
        onClick={handleNext}
      >
        <AiOutlineRight style={{ fontSize: 14, color: 'white', }} />
      </Button>
      {/* </div> */}
    </div>

  );
}

export default Pagination;