import React, { useState, useEffect, } from 'react';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { AiOutlineSearch, } from 'react-icons/ai';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  getIngredientList,
} from '../../../store/app/actions';
import {
  SignEdit,
  Button,
  CheckBox,
  Pagination,
} from '../../../components';
import { updateProfile } from '../../../store/session';
import { history } from '../../../store/history';


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 52,
    paddingLeft: 30,
    marginBottom: 20,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    paddingLeft: 30,
  }),
};


const Wizard = () => {

  const dispatch = useDispatch();

  const countries = useSelector(state => state.app.countries);
  const cuisines = useSelector(state => state.app.cuisines);
  const vegetarianTypes = useSelector(state => state.app.vegetarianTypes);
  const ingredientInfo = useSelector(state => state.app.ingredientInfo);
  const profile = useSelector(state => state.session.profile);

  const [name, setName] = useState('');
  const [email, setEmail] = useState(profile ? profile.email : '');
  const [city, setCity] = useState('');
  const [step, setStep] = useState(1);
  const [country, setCountry] = useState(null);
  const [birthDay, setBirthDay] = useState(new Date());
  const [gender, setGender] = useState("male");
  const [ingredientPage, setIngredientPage] = useState(1);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedVegetarianTypes, setSelectedVegetarianTypes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [keywords, setKeywords] = useState({
    cuisine: '',
    vegetarianType: '',
    ingredient: '',
  });
  const [search, setSearch] = useState({
    cuisine: '',
    vegetarianType: '',
    ingredient: '',
  });

  const count = 10;
  const language = profile?.langId ?? 1;

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const handleContinue = (val) => {
    if (val == 2) {
      if (!name || !city || !country) {
        return;
      }
    }

    setStep(val);
  }

  const handleCuisineCheck = (id) => {
    let tmpCuisines = selectedCuisines.slice();
    if (!tmpCuisines.includes(id)) {
      tmpCuisines.push(id);
    } else {
      const index = tmpCuisines.indexOf(id);
      tmpCuisines.splice(index, 1);
    }

    setSelectedCuisines(tmpCuisines);
  }

  const handleVegetarianCheck = (id) => {
    let tmpVegetarianTypes = selectedVegetarianTypes.slice();
    if (!tmpVegetarianTypes.includes(id)) {
      tmpVegetarianTypes.push(id);
    } else {
      const index = tmpVegetarianTypes.indexOf(id);
      tmpVegetarianTypes.splice(index, 1);
    }

    setSelectedVegetarianTypes(tmpVegetarianTypes);
  }

  const handleIngredientCheck = (e, code) => {
    let tmpIngredients = ingredients.slice();
    if (!tmpIngredients.includes(code)) {
      if (tmpIngredients.length > 4) {
        e.preventDefault();

        return;
      }

      tmpIngredients.push(code);
    } else {
      const index = tmpIngredients.indexOf(code);
      tmpIngredients.splice(index, 1);
    }

    setIngredients(tmpIngredients);
  }

  const handlePageChange = (val) => {
    setIngredientPage(val);

    dispatch(getIngredientList(val, count, 'code', 'asc', keywords.ingredient));
  }

  const handleFinish = () => {
    let updatedprofile = {};
    updatedprofile.name = name;
    updatedprofile.email = email;
    updatedprofile.city = city;
    updatedprofile.country = country.value;
    updatedprofile.birthday = birthDay;
    updatedprofile.gender = gender;
    updatedprofile.cuisineList = selectedCuisines;
    updatedprofile.vegetarianTypeList = selectedVegetarianTypes;
    updatedprofile.unlikeList = ingredients;
    updatedprofile.userGroupId = 1;

    dispatch(updateProfile(updatedprofile));

    history.push('/home');
  }

  const handleSearch = (type, e) => {
    if (type === 'ingredient') {
      handlePageChange(1);
    } else {
      setSearch({ ...search, [type]: keywords[type] });
    }

    if (e)
      e.preventDefault();
  }

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  if (!countries) {
    return <div></div>
  }

  let countryOptions = [];
  for (const id in countries) {
    countryOptions.push({
      value: id,
      label: countries[id].name,
    });
  }


  return (
    <div style={{ width: '100%', backgroundColor: 'gray', paddingTop: 100, paddingBottom: 100, }}>
      <div className="preferences-wizard">
        {
          step === 1 ? (countryOptions ? (
            <div>
              <div className="d-flex flex-column" style={{ width: '80%', margin: 'auto', padding: '30px 0' }}>
                <SignEdit
                  placeholder="User Name"
                  style={{ margin: 0, width: '100%', marginBottom: 20, }}
                  value={name}
                  onChange={val => setName(val)}
                />
                <SignEdit
                  placeholder="Email"
                  readOnly
                  style={{ margin: 0, width: '100%', marginBottom: 20, }}
                  value={email}
                  onChange={val => setEmail(val)}
                />
                <Select
                  options={countryOptions}
                  isSearchable={true}
                  styles={customStyles}
                  value={country}
                  onChange={val => setCountry(val)}
                />
                <SignEdit
                  placeholder="City"
                  style={{ margin: 0, width: '100%', marginBottom: 20, }}
                  value={city}
                  onChange={val => setCity(val)}
                />
                <div className="d-flex align-items-center">
                  <span className="mr-4">Birth Date :</span>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={birthDay}
                    onChange={(date) => setBirthDay(date)}
                    autoOk={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </div>
                <div className="d-flex align-items-center" style={{ marginTop: 20, }}>
                  <span className="mr-5">Gender :</span>
                  <RadioGroup aria-label="gender" row={true} name="gender1" value={gender} onChange={handleChangeGender}>
                    <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" />
                    <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" />
                  </RadioGroup>
                </div>
                <Button
                  variant="contained"
                  bgColor="primary"
                  fontColor="white"
                  width={162}
                  height={52}
                  style={{ margin: 'auto', marginTop: 30, }}
                  onClick={() => handleContinue(2)}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : <ClipLoader color='#FC9C52' loading={true} size={35} />
          ) : step === 2 ? (
            <div className="d-flex flex-column">
              <Button
                variant=""
                bgColor="primary"
                width={40}
                height={40}
                style={{ padding: 0, marginLeft: 5, }}
                onClick={() => setStep(step - 1)}
              >
                <AiOutlineLeft style={{ fontSize: 26, color: '#3490DD' }} />
              </Button>
              <p className="text-center h5">
                Please select cuisine types.
              </p>
              <div className="d-flex justify-content-end mt-4">
                <form className="d-flex align-items-center" style={{ backgroundColor: '#F5F8FE', borderRadius: 6, height: 40, paddingRight: 20, }} onSubmit={e => handleSearch('cuisine', e)}>
                  <input
                    placeholder="Search"
                    style={{ border: 'none', outline: 'none', paddingLeft: 20, borderRadius: 6, fontSize: 16, width: '100%', height: '100%', color: '#07122E', backgroundColor: '#F5F8FE' }}
                    value={keywords.cuisine}
                    onChange={val => setKeywords({ ...keywords, cuisine: val.target.value })}
                  />
                  <AiOutlineSearch className="pointer" style={{ fontSize: 18, marginRight: 5 }} onClick={() => handleSearch('cuisine')} />
                </form>
              </div>
              <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, height: 250, overflowY: 'scroll', padding: '0 15%', marginTop: 10, }}>
                {
                  cuisines ? (
                    Object.entries(cuisines).map((cuisine, index) => (
                      cuisine[1].name.includes(search.cuisine) ? (
                        <CheckBox
                          style={{ padding: '5px 0' }}
                          id={`cuisine_${cuisine[0]}`}
                          key={`cuisine_${index}`}
                          onClick={() => handleCuisineCheck(parseInt(cuisine[0]))}
                        >
                          {cuisine[1].name}
                        </CheckBox>
                      ) : null
                    ))
                  ) : (
                    <div className="HV-center h-100">
                      <ClipLoader color='#FC9C52' loading={true} size={35} />
                    </div>
                  )
                }
              </div>
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                width={162}
                height={52}
                style={{ margin: 'auto', marginTop: 30, }}
                onClick={() => handleContinue(3)}
              >
                Continue
              </Button>
            </div>
          ) : step === 3 ? (
            <div className="d-flex flex-column">
              <Button
                variant=""
                bgColor="primary"
                width={40}
                height={40}
                style={{ padding: 0, marginLeft: 5, }}
                onClick={() => setStep(step - 1)}
              >
                <AiOutlineLeft style={{ fontSize: 26, color: '#3490DD' }} />
              </Button>
              <p className="text-center h5">
                Please select vegetarian types.
              </p>
              <div className="d-flex justify-content-end mt-4">
                <form className="d-flex align-items-center" style={{ backgroundColor: '#F5F8FE', borderRadius: 6, height: 40, paddingRight: 20, }} onSubmit={e => handleSearch('vegetarianType', e)}>
                  <input
                    placeholder="Search"
                    style={{ border: 'none', outline: 'none', paddingLeft: 20, borderRadius: 6, fontSize: 16, width: '100%', height: '100%', color: '#07122E', backgroundColor: '#F5F8FE' }}
                    value={keywords.vegetarianType}
                    onChange={val => setKeywords({ ...keywords, vegetarianType: val.target.value })}
                  />
                  <AiOutlineSearch className="pointer" style={{ fontSize: 18, marginRight: 5 }} onClick={() => handleSearch('vegetarianType')} />
                </form>
              </div>
              <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, height: 250, overflowY: 'scroll', padding: '0 15%', marginTop: 10, }}>
                {
                  vegetarianTypes ? (
                    Object.entries(vegetarianTypes).map((vegetarianType, index) => (
                      vegetarianType[1].name.includes(search.vegetarianType) ? (
                        <CheckBox
                          style={{ padding: '5px 0' }}
                          id={`vegetarianType_${vegetarianType[0]}`}
                          key={`vegetarianType_${index}`}
                          onClick={() => handleVegetarianCheck(parseInt(vegetarianType[0]))}
                        >
                          {vegetarianType[1].name}
                        </CheckBox>
                      ) : null
                    ))
                  ) : (
                    <div className="HV-center h-100">
                      <ClipLoader color='#FC9C52' loading={true} size={35} />
                    </div>
                  )
                }
              </div>
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                width={162}
                height={52}
                style={{ margin: 'auto', marginTop: 30, }}
                onClick={() => handleContinue(4)}
              >
                Continue
              </Button>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <Button
                variant=""
                bgColor="primary"
                width={40}
                height={40}
                style={{ padding: 0, marginLeft: 5, }}
                onClick={() => setStep(step - 1)}
              >
                <AiOutlineLeft style={{ fontSize: 26, color: '#3490DD' }} />
              </Button>
              <p className="text-center h5">
                Please select 5 unlike ingredients.
              </p>
              <div className="d-flex justify-content-end mt-4">
                <form className="d-flex align-items-center" style={{ backgroundColor: '#F5F8FE', borderRadius: 6, height: 40, paddingRight: 20, }} onSubmit={e => handleSearch('ingredient', e)}>
                  <input
                    placeholder="Search"
                    style={{ border: 'none', outline: 'none', paddingLeft: 20, borderRadius: 6, fontSize: 16, width: '100%', height: '100%', color: '#07122E', backgroundColor: '#F5F8FE' }}
                    value={keywords.ingredient}
                    onChange={val => setKeywords({ ...keywords, ingredient: val.target.value })}
                  />
                  <AiOutlineSearch className="pointer" style={{ fontSize: 18, marginRight: 5 }} onClick={() => handleSearch('ingredient')} />
                </form>
              </div>
              <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, height: 250, overflowY: 'scroll', padding: '0 15%', marginTop: 10, }}>
                {
                  ingredientInfo && ingredientInfo.ingredients ? (
                    ingredientInfo.ingredients.map((ingredient, index) => (
                      <CheckBox
                        style={{ padding: '5px 0' }}
                        id={`ingredient_${ingredient.code}`}
                        key={`ingredient${ingredient.code}`}
                        onClick={(e) => handleIngredientCheck(e, ingredient.code)}
                      >
                        {ingredient.friendlyDescription[language]}
                      </CheckBox>
                    ))
                  ) : (
                    <div className="HV-center h-100">
                      <ClipLoader color='#FC9C52' loading={true} size={35} />
                    </div>
                  )
                }
              </div>
              <Pagination
                value={ingredientPage ?? 1}
                maxPage={ingredientInfo?.pages}
                onUpdate={(val) => handlePageChange(val)}
                onChange={setIngredientPage}
              />
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                width={162}
                height={52}
                style={{ margin: 'auto', marginTop: 30, }}
                onClick={handleFinish}
              >
                Finish
              </Button>
            </div>
          )
        }

      </div>
    </div>
  );
};

export default Wizard;
